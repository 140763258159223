
<div class="upperText">
  Please Select A Supplier Code From The Dropdown Or Enter A Custom Supplier
  Code To Search
</div>
<div class="container">
  <div class="tile">
    <form [formGroup]="myForm">
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierCode">SUPPLIER CODE</label>
        </div>
        <p-dropdown
          formControlName="supplierCode"
          [options]="supplierCodeList"
          optionLabel="supplierCode"
          optionValue="supplierCode"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          [virtualScrollItemSize]="10"
          (onChange)="supplierCodeChange()"
        >
        </p-dropdown>
        <div
          *ngIf="
            myForm.get('supplierCode')?.touched &&
            myForm.get('supplierCode')?.invalid &&
            !myForm.value.customSupplierCode
          "
        >
          <small
            *ngIf="myForm.get('supplierCode')?.errors?.['required']"
            class="errorValid"
            >Supplier Code is required.</small
          >
        </div>
      </div>
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierName">SUPPLIER NAME</label>
        </div>
        <p-dropdown
          formControlName="supplierName"
          [options]="supplierCodeList"
          [virtualScrollItemSize]="10"
          optionLabel="supplierName"
          optionValue="supplierName"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          (onChange)="supplierNameChange()"
        >
        </p-dropdown>
      </div>
      <div class="input-container">
        <label for="customSupplierCode">CUSTOM SUPPLIER CODE</label>
        <input
          formControlName="customSupplierCode"
          type="text"
          id="customSupplierCode"
          name="customSupplierCode"
          (change)="customSupplierCodeChange()"
        />
        <button class="b2 add" (click)="onSubmit()">SUBMIT</button>
      </div>
    </form>
  </div>
</div>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div *ngIf="showGrid" class="ag-container">
  <div class="headerinfo">
    <div class="mb-0 pt-2 ps-2">
      {{ selectedSupplierName }} [ {{ selectedSupplierCode }} ] CONTACT
      INFORMATION
    </div>
  </div>
  <app-grid
    [columnDefs]="columnDefs"
    [displayRowData]="displayRowData"
    [rowSelection]="rowSelection"
    (select)="onRowSelection($event)"
    class="gridCss"
  ></app-grid>
</div>
<div class="create_footer fixed-bottom">
  <button
    *ngIf="showGrid"
    class="black-button blackbtnalign me-3 mt-2"
    (click)="openSendSuppNotification()"
  >
    SEND SUPPLIER NOTIFICATION
  </button>
  <button
    class="export-btn blackbtnalign me-3 mt-2"
    *ngIf="showGrid"
    (click)="exportSupplierList()"
  >
    EXPORT SUPPLIER LIST
  </button>
</div>
