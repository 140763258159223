import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import { EventList } from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';

@Component({
  selector: 'app-trial-orders',
  templateUrl: './trial-orders.component.html',
  styleUrls: ['./trial-orders.component.scss'],
})
export class TrialOrdersComponent implements OnInit {

  requiredDisplayCheck = true;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventList: EventList[] = [];
  isBtnEnable = false;
  showError = false;
  eventId = '';
  selectedRole: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  allSelection = false;
  deletedEvent = false;

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderervice: TrialOrdersService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.onLoadEventList();
    this.selectedRole = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
  }

  eventDropdownChange(event: any) {
    const eventStatus = (this.eventList.find(value => value.eventId === event.value)?.eventStatus)?.toString();
    this.showError = false;
    this.eventId = event.value;
    if (event.value === 0) {
      this.isBtnEnable = false;
      this.deletedEvent = false;
      this.allSelection = true;
    }else if(eventStatus === '2'){
      this.allSelection = false;
      this.isBtnEnable = false;
      this.deletedEvent = true;
    }
    else {
      this.allSelection = false;
      this.deletedEvent = false;
      this.isBtnEnable = true;  
    }
  }

  onLoadEventList() {
    this.ngxUiLoaderService.start('eventList');
    this.trialOrderervice.getEventList()?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('eventList');
        this.eventList = result;
        this.eventList.unshift({ eventId: 0, eventName: 'All', eventStatus: 0 });
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('eventList');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  displayTrialOrder() {
    if (this.isBtnEnable === false && this.allSelection === false && this.deletedEvent === false) {
      this.showError = true;
    } else if(this.selectedRole.role === 'namc'){
      this.router.navigate(['event-order/trial-orders/trialOrderList'], {
        state: { myData: this.eventId },
      });
      }
    else{
      this.router.navigate(['event-order/trial-orders/supplierTrialOrderList'], {
        state: { myData: this.eventId },
      });
    }
  }

  displayHistory() {
    this.router.navigate(['event-order/trial-orders/trialOrderHistory'], {
      state: { myData: this.eventId },
    });
  }

  displayShippingAddress() {
    this.router.navigate(['event-order/trial-orders/shippingAddress'], {
      state: { myData: this.eventId },
    });
  }
}
