
<div class="addAttribute-btn">
</div>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
></app-grid>
