<button
  mat-raised-button
  color="primary"
  (click)="collapseAll()"
  class="d-flex mt-2 mb-2 ms-md-auto me-3 bg-dark text-white border-0 rounded-0"
>
  {{ allPanelsCollapsed ? "Collapse All" : "Expand All" }}
</button>

<mat-accordion multi>
  <ng-container *ngFor="let category of documents">
    <mat-expansion-panel
      *ngIf="category?.documentList?.length"
      [expanded]="true"
    >
      <mat-expansion-panel-header class="shadow-none">
        <mat-panel-title>
          {{ category.catnm }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table
        mat-table
        [dataSource]="category.documentList"
        class="mat-elevation-z8 custom-table"
      >
        <ng-container matColumnDef="document">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="height: 40px"
            class="text-dark"
          >
            DOCUMENT
          </th>
          <td mat-cell *matCellDef="let doc">
            <div>
              <a (click)="downloadDocument(doc.docid, doc.docname)">{{
                doc.doctitletext
              }}</a>
            </div>
            <div class="d-flex">
              <b style="font-weight: 590">File : </b>
              <div style="font-weight: normal !important">
                {{ doc.docname }}
              </div>
            </div>
            <div class="d-flex">
              <b style="font-weight: 590">Description : </b>
              <div style="font-weight: normal !important">
                {{ doc.descriptiontext }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="text-dark">DATE</th>
          <td mat-cell *matCellDef="let doc">
            {{ doc.updatetimestamp ?? doc.createtimestamp }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fileSize">
          <th mat-header-cell *matHeaderCellDef class="text-dark">FILE SIZE</th>
          <td mat-cell *matCellDef="let doc">{{ doc.fileSize }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers"></tr>
      </table>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
