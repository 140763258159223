import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import {
  AttributeDetails,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-trial-orders-supplier',
  templateUrl: './trial-orders-supplier.component.html',
  styleUrls: ['./trial-orders-supplier.component.scss'],
})
export class TrialOrdersSupplierComponent implements OnInit {
  columnDefs: ColDef[] = [];
  displayRowData: TrialOrderDetails[] = [];
  namcCode = '';
  currentRole = '';
  user: User | null = null;
  allEvents = true;
  rowSelection: 'single' | 'multiple' | undefined = 'multiple';
  eventId = '';
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };
  limit = 10;

  constructor(
    private readonly userService: UserService,
    private readonly trialOrderService: TrialOrdersService,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.eventId = String(history.state?.myData);
    this.setEventDetails();
    this.getGridData();
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
      }
    });
  }

  loadRoleDetails() {
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      const role = this.user?.roles.find(
        (value) => value.namcCode === selectedRoles?.namc
      );
      this.currentRole = role?.roleDescription ?? '';
      this.namcCode = role?.namcCode ?? '';
    });
  }

  setEventDetails() {
    this.ngxUiLoaderService.start('setupevent');
    this.trialOrderService.getEventDetails(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('setupevent');
        this.eventDetailsFlags = result;
        this.getColDefs();
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('setupevent');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getColDefs() {
    this.columnDefs = [
      {
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        checkboxSelection: true,
        minWidth: 60,
        maxWidth: 60,
        pinned: 'left',
      },
      {
        headerName: 'ORDER STATUS',
        field: 'status',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return (
            `<span class="status-holder 
            ${params.value}
            "> 
            ${params.value.replace('_', ' ')}
            </span>`
          );
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 195,
        minWidth: 195,
      },
      {
        headerName: 'ORDER TRACKING NO',
        field: 'orderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        headerName: 'ORDER TYPE',
        field: 'orderType',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 130,
        minWidth: 130,
      },
      {
        headerName: 'PO Tracking No',
        field: 'poTrackingNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        headerName: 'EVENT NAME',
        field: 'eventName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return this.renderTitle(params);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 210,
        minWidth: 210,
      },
      {
        headerName: 'PROJECT',
        field: 'projectCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 90,
        minWidth: 90,
      },
      {
        headerName: 'SPECIALIST NAME',
        field: 'specialistName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 210,
        minWidth: 210,
      },
      {
        headerName: 'SUPPLIER CODE',
        field: 'supplierCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 90,
        minWidth: 90,
      },
      {
        headerName: 'KANBAN NUMBER',
        field: 'kanbanNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 150,
        minWidth: 150,
      },
      {
        headerName: 'PART NUMBER',
        field: 'partNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 170,
        minWidth: 150,
      },
      {
        headerName: 'PART NAME',
        field: 'partName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
        maxWidth: 210,
      },
      {
        headerName: 'REVISION',
        field: 'revision',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        headerName: 'NAMC DUE DATE',
        field: 'orderDueDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER PICKUP DATE',
        field: 'supplierPickupDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER SHIP DATE',
        field: 'promiseDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TOTAL QTY',
        field: 'totalQty',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'NAMC COMMENT',
        field: 'namcComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'SUPPLIER COMMENT',
        field: 'supplierComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 1000) {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'STORE ADDRESS',
        field: 'storeAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: 'LINE SIDE ADDRESS',
        field: 'lineSideAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: 'NAMC CONTACT',
        field: 'namcEmail',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        headerName: 'DOCK CODE',
        field: 'dockCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'PALLET CODE',
        field: 'palletCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'MANIFEST NUMBER',
        field: 'manifestNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'RECEIVER NUMBER',
        field: 'orderReceiverNbr',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'NA PIPE STATUS',
        field: 'naPipeStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'ECI NUMBER',
        field: 'eciNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 9) {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'ECI REVISION',
        field: 'eciRevision',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 2) {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'SPECIALIST CODE',
        field: 'specialistCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'SHIPPING DATE',
        field: 'shippingDateTime',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        cellStyle: (params) => {
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          if (params.value.length === 0) {
            return { color: 'black' };
          } else if (!dateRegex.test(params.value)) {
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TRAILER NUMBER',
        field: 'trailerNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'SHIPPING CARRIER',
        field: 'shippingCarrier',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'TRACKING/AIRLBILL NO',
        field: 'trackingAirbillNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        cellStyle: (params) => {
          if (params.value.length > 30) {
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        maxWidth: 165,
      },
      {
        headerName: 'LIFE CYCLE CODE',
        field: 'lifeCycleCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'WORK ORDER NUMBER',
        field: 'workOrderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 180,
        maxWidth: 180,
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE REQUEST',
        field: 'requestForSuppShipDtChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 1000) {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        maxWidth: 1000,
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE COMMENT',
        field: 'requestForSuppShipcmmntChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        cellStyle: (params) => {
          if (params.value != null) {
            if (params.value.length > 1000) {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        maxWidth: 1000,
      },
      {
        headerName: 'ARIBA PR NO',
        field: 'aribaPrNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        maxWidth: 160,
      },
      {
        headerName: 'ARIBA ORDER STATUS',
        field: 'aribaOrderStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        headerName: 'ARIBA ORDER COMMENT',
        field: 'aribaOrderComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 350,
      },
    ];
  }

  onPaginationChanged(_event: number) {
    this.limit = _event;
    this.getGridData();
  }

  getGridData() {
    this.ngxUiLoaderService.start('griddata');
    this.trialOrderService.getGridData(this.eventId, 1, this.limit)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('griddata');
        this.displayRowData = result.trialOrderData as TrialOrderDetails[];
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('griddata');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  renderTitle(params: { value: string }) {
    if (params.value && params.value !== '') {
      const displayValue =
      params.value.length > 25
        ? params.value.substring(0, 25) + '...'
        : params.value;
    return `<span title="${params.value}">${displayValue}</span>`;
    }
    return params.value;
  }

  renderEditTitle(params: { value: string }) {
    const { isAllEvents } = this.eventDetailsFlags;
    if (isAllEvents === 'false') {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }

  renderConditionalEdit1(params: any) {
    if (
      this.eventDetailsFlags.isAllEvents === 'false' &&
      params.data.status === 'Firmed'
    ) {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }

  cancel() {
    window.history.back();
  }
}
