
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>

<div *ngIf="validationMessages.length > 0" class="alert alert-danger">
  <ul>
    <li *ngFor="let message of validationMessages">{{ message }}</li>
  </ul>
</div>

<div class="form-group mt-3">
  <div class="actual-label-container">
    <div class="vertical-bar"></div>
    <label for="upload">UPLOAD</label>
  </div>
  <select
    id="upload"
    (change)="showForm($event)"
    class="dropDown"
    [(ngModel)]="selectDrop"
  >
    <option value="">Select Event</option>
    <option value="1">Upload Order</option>
    <option value="2">Upload Updated Order</option>
  </select>
</div>
<form
  [formGroup]="eventForm"
  (ngSubmit)="onSubmit()"
  class="form-container"
  *ngIf="hideForm"
>
  <div class="form-group">
    <div class="label-container">
      <div class="vertical-bar"></div>
      <label for="event">EVENT</label>
    </div>
    <select
      id="event"
      formControlName="event"
      [ngClass]="{'is-invalid': eventForm.get('event')?.touched && eventForm.get('event')?.invalid && eventForm.get('event')?.errors?.['required']}"
      (change)="setDetails()"
    >
      <option value="" style="font-size: 13px; top: -8px">Select Event</option>
      <option *ngFor="let event of events" [value]="event.eventId">
        {{ event.eventName }}
      </option>
    </select>
    <div
      *ngIf="eventForm.get('event')?.touched && eventForm.get('event')?.invalid"
    >
      <small
        *ngIf="eventForm.get('event')?.errors?.['required']"
        class="errorValid"
        >File is required.</small
      >
    </div>
  </div>
  <div class="form-group">
    <label for="eventDescription">EVENT DESCRIPTION</label>
    <input
      id="eventDescription"
      formControlName="eventDescription"
      type="text"
      class="form-control"
    />
  </div>
  <div class="form-group">
    <label for="eventType">EVENT TYPE</label>
    <input
      id="eventType"
      formControlName="eventType"
      type="text"
      class="form-control"
    />
  </div>
  <div class="form-group">
    <label for="projectCode">PROJECT CODE</label>
    <input
      id="projectCode"
      formControlName="projectCode"
      type="text"
      class="form-control"
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <div class="vertical-bar"></div>
      <label for="file">FILE</label>
    </div>
    <input id="file" type="file" (change)="onFileChange($event)" accept=".xls, .xlsx" hidden />
    <button
      type="button"
      class="custom-file-upload"
      (click)="fileInput.click()"
    >
      Choose File
    </button>
    <input
      type="file"
      #fileInput
      (change)="onFileChange($event)"
      style="display: none"
    />
    <div *ngIf="uploadedFileName" class="file-info">
      <span>{{ uploadedFileName }}</span>
      <div class="btn" (click)="removeFile()" (keydown)="removeFile()">
        &#x2212;
      </div>
    </div>
    <div
      *ngIf="eventForm.get('file')?.touched && eventForm.get('file')?.invalid"
    >
      <small
        *ngIf="eventForm.get('file')?.errors?.['required']"
        class="errorValid"
        >File is required.</small
      >
    </div>
  </div>
  <footer class="footer">
    <div class="button-container">
      <button class="b2 submit">UPLOAD ORDER FILES</button>
    </div>
  </footer>
</form>
