import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ManageTrialEventsComponent } from 'src/app/pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { TrialEventsList } from '../../model/manage-trial-events.model';

@Component({
  selector: 'app-trial-events-action',
  template: `<button
      class="me-3 edit"
      [disabled]="!params.data?.actionBtnCheck || ( params.data?.eventStatus === 'COMPLETED' ||
        params.data?.eventStatus === 'DELETED' ||
        params.data?.eventStatus === 'UNKNOWN')"
      (click)="editEvent()"
    >
      <fa-icon
        [icon]="faEdit"
        title="Edit Event"
      ></fa-icon>
    </button>
    <button
      [disabled]="!params.data?.actionBtnCheck || ( params.data?.eventStatus === 'COMPLETED' ||
        params.data?.eventStatus === 'DELETED' ||
        params.data?.eventStatus === 'UNKNOWN')"
      (click)="deleteEvent()"
      class="edit"
    >
      <fa-icon
        [icon]="faDelete"
        title="Delete Event"
      ></fa-icon>
    </button> `,
    styles: [
      `
        .edit {
          width: 26px;
          height: 26px;
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #d1d1d1;
           border-radius: 4px;
          opacity: 1;
          box-shadow: 0.2s;
        }
        .edit:active{
          transform: scale(0.95) !important;
          box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
        }
        button:disabled {
       cursor: not-allowed;
      }
      `,
    ],
})
export class TrialEventsAction implements ICellRendererAngularComp {
  params!: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }
  >;
  faEdit = faPencilAlt;
  faDelete = faTrashAlt;
  constructor(private readonly router: Router) {}

  refresh(_params: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }>): boolean {
    return false;
  }

  agInit(params: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }>): void {
    this.params = params;
  }

  editEvent() {
    this.router.navigate(['/event-order/manage-trial-events/edit-event'], {
      queryParams: this.params.data,
    });
  }

  deleteEvent() {
    //No work
    if (this.params.data) {
      this.params?.context.componentParent.deleteEvent(
        this.params.data.eventId,
        this.params.data.userId,
        this.params.data.eventName
      );
    }
  }
}
