import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { FullUserData } from 'src/app/core/model/manage-user';
import { NamcRole } from 'src/app/core/model/namc.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddEditUserService {
  baseApiUrl: string;
  message: string | undefined = undefined;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getNamcList(operation: string) {
    return this.http
      .get<NamcRole>(`${this.baseApiUrl}/tmna-namc/user/init?operation=${operation}`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  searchUserDetails(userId: string,operation: string) {
    return this.http
      .get<FullUserData>(`${this.baseApiUrl}/tmna-namc/user?userId=${userId}&operation=${operation}`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  submitUserDetails(payload: any) {
    return this.http
      .post(`${this.baseApiUrl}/tmna-namc/user`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
  setMessage(msg?: string) {
    this.message = msg;
  }
  getMessage() {
    return this.message;
  }
}
