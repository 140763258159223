import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'tproms-ui';
  hideFooter = false;
  requiredDisplayCheck = false;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    const e = document.getElementById('footerheight')?.offsetHeight ?? 10;
    const h = document.getElementById('headerHeight')?.offsetHeight ?? 10;
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('footerheight', e.toString());
    localStorage.setItem('headerHeight', h.toString());
    localStorage.setItem('toolbarheight', t.toString());
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Only listen to NavigationEnd
    ).subscribe(() => {
      this.setRequiredFieldValue();
    });
  }
  ngAfterViewInit() {
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('toolbarheight', t.toString());
  }
  setRequiredFieldValue(){
      const urlsToCheck = [
        'modify-attribute', 'add-new-attribute', 'modify-configuration', 'create-new-event', 
        'view-event', 'edit-event', 'manage-namc-documents/add', 'manage-namc-documents/edit',
        'manage-online-training/add', 'manage-online-training/edit', 'manage-users/add', 
        'manage-users/edit', 'trial-orders', 'select-roles', 'uploadOrders', 'contacts',
        'loadMassEmail'
      ];
      this.requiredDisplayCheck = urlsToCheck.some(url => this.router.url.includes(url));
    
  }
}
