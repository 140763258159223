<div class="d-flex justify-content-end">
  <button class="mt-3 me-2">
    <fa-icon
      [icon]="faFilter"
      title="Filter Grid"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2">
    <fa-icon
      [icon]="faEye"
      title="View Single Order"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2">
    <fa-icon
      [icon]="faCheck"
      title="Confirm Single Order Supplier Ship Date"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2">
    <fa-icon
      [icon]="faCheckSquareO"
      title="Confirm Selected Orders Supplier Ship Date"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2" style="width: 60px">ECI</button>

  <button class="mt-3 me-2" *ngIf="eventDetailsFlags.isAllEvents === 'false'">
    <fa-icon [icon]="faDownload" title="Download Labels Template"></fa-icon>
  </button>
  <button class="mt-3 me-2" *ngIf="eventDetailsFlags.isAllEvents === 'false'">
    <fa-icon
      [icon]="faTruck"
      class="d-flex justify-content-center"
      title="Download Shipping Template"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2">
    <fa-icon [icon]="faShareFromSquare" title="Export Orders"></fa-icon>
  </button>

  <button
    class="mt-3 me-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false'
    "
  >
    <fa-icon [icon]="faComment" title="Supplier Comments"></fa-icon>
  </button>

  <button
    class="mt-3 me-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false'
    "
  >
    <fa-icon
      [icon]="faWeibo"
      title="Supplier Ship Date Change Request"
    ></fa-icon>
  </button>
</div>
