import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { ManageConfigurationsService } from 'src/app/services/tmna-namc/manage-configurations.service';

@Component({
  selector: 'app-modify-configuration',
  templateUrl: './modify-configuration.component.html',
  styleUrls: ['./modify-configuration.component.scss'],
})
export class ModifyConfigurationComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  public modifyConfigurationForm: FormGroup = {} as FormGroup;
  requiredDisplayCheck = true;
  configId!: number;
  configKey = '';

  constructor(
    private readonly router: Router,
    public formbuilder: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly configService: ManageConfigurationsService,
    private readonly dialog: MatDialog
  ) {
    this.modifyConfigurationForm = this.formbuilder.group({
      configValue: ['', Validators.required],
      configDescription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const paramData = JSON.parse(sessionStorage.getItem("modifyConfigurationParams") ?? '{}');
    if (Object.keys(paramData).length !== 0) {
      this.configId = paramData.configId;
      this.configKey = paramData.configKeyName;
      this.modifyConfigurationForm.patchValue({
        configValue: paramData.configKeyValue,
        configDescription: paramData.commentsText,
      });
    }
    else{
      this.errorMessage = "Please select a configuration from Manage Configurations page.";
      this.settingTimeOut();
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '219px',
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        this.router.navigate(['/tmna-namc/manage-configurations']);
      }
    });
  }
  updateConfigurationPayload() {
    return {
      configId: this.configId,
      configKeyValue:
        this.modifyConfigurationForm.controls['configValue'].value,
      commentsText:
        this.modifyConfigurationForm.controls['configDescription'].value,
    };
  }

  updateConfiguration() {
    this.modifyConfigurationForm.markAllAsTouched();
    const modifyConfigData = this.updateConfigurationPayload();
    this.ngxUiLoaderService.start('modifyConfigurationList');
    this.configService.modifyConfigurationList(modifyConfigData).subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('modifyConfigurationList');
        this.configService.setMessage(`Configuration '${this.configKey}' Updated Successfully.`);
        this.router.navigate(['/tmna-namc/manage-configurations']);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('modifyConfigurationList');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
