
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-namc-trial-order-buttons
  [eventId]="eventId"
  [currentRole]="currentRole"
  [eventDetailsFlags]="eventDetailsFlags"
></app-namc-trial-order-buttons>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  [rowSelection]="rowSelection"
  (pageLimit)="onPaginationChanged($event)"
></app-grid>
<div class="create_footer fixed-bottom">
  <button
    class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
    (click)="cancel()"
  >
    BACK
  </button>
  <button
    class="black-button blackbtnalign me-3 mt-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false' &&
      (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
    "
  >
    SAVE
  </button>
  <button
    class="black-button blackbtnalign me-3 mt-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false' &&
      (this.currentRole === 'IS Admin' ||
        this.currentRole === 'QE User' ||
        this.currentRole === 'PE User' ||
        this.currentRole === 'Shop User' ||
        this.currentRole === 'PC User')
    "
    disabled
  >
    SAVE
  </button>
</div>
