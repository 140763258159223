import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  PieBarLatestatistics,
  SupplierListMap,
} from 'src/app/core/model/performance.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnInit {
  lateOrders!: string[];
  supplierCodeList: SupplierListMap[] = [];
  errorMessage: string | undefined;
  successMessage: string | undefined;
  myForm: FormGroup;
  last12Months: string[] = [];
  selectedSupplier!: string;
  originalData: { Rated: string | null }[] = [];
  originalLabels: string[] = this.generateLabels();

  lineChartData: ChartDataset[] = [];
  lineChartLabels: number[] = this.pastThreeYears();
  lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };
  lineChartLegend = false;
  lineChartType: ChartType = 'line';

  barChartData: ChartDataset[] = [];
  barChartLabels: string[] = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };
  barChartLegend = false;
  barChartType: ChartType = 'bar';
  isSupplier!: boolean;
  selectedRoleDetail: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  rgbaColor = 'rgba(5, 148, 250, 1)';

  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly performanceService: PerformanceService,
    private readonly router: Router
  ) {
    this.myForm = this.fb.group({
      supplierCode: [''],
      supplierName: [''],
    });
  }

  ngOnInit(): void {
    this.isSupplier = sessionStorage.getItem('roleName') === 'Supplier';
    if (this.isSupplier) {
      this.selectedRoleDetail = JSON.parse(
        sessionStorage.getItem('roleDetails') ?? '{}'
      );
      if (this.selectedRoleDetail.childSupplierCode === '-1'){
        this.pieBarstatistics(this.selectedRoleDetail.childSupplierCode);
      }
      else { this.pieBarstatistics(this.selectedRoleDetail.supplierCode);}
    } else {
      this.pieBarstatistics('');
      this.fetchSupplierDetails();
    }
  }

  fetchSupplierDetails() {
    this.ngxUiLoaderService.start('loader-01');
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodeList = res;
        this.ngxUiLoaderService.stop('loader-01');
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.setErrorMessage('Something went wrong');
      },
    });
  }

  generateLabels(): string[] {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const labels = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = (currentMonth + i) % 12;
      const year = currentYear + Math.floor((currentMonth + i) / 12) - 1;
      labels.push(`${months[monthIndex]} ${year}`);
    }

    return labels;
  }

  onSubmit() {
    this.pieBarstatistics(this.myForm.value.supplierCode);
  }

  pieBarstatistics(supplierCode: string | undefined) {
    if (supplierCode?.length === 6) {
      supplierCode = supplierCode?.substring(2);
    }
    this.ngxUiLoaderService.start('loader-02');
    this.performanceService.pieBarstatistics(supplierCode)?.subscribe({
      next: (res: PieBarLatestatistics) => {
        this.ngxUiLoaderService.stop('loader-02');
        this.selectedSupplier = this.myForm.value.supplierCode;
        this.setLineChartData(res);

        this.originalData = res.monthlyPerformance;
        this.setBarChartData();

        this.lateOrders = res.lateOrders.map((element) => {
          return element.isExists;
        });
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-02');
        this.setErrorMessage('Something went wrong');
      },
    });
  }

  setLineChartData(res: PieBarLatestatistics) {
    this.lineChartData = [
      {
        data: res.annualPerformance.map((element) => {
          return Math.floor(Number(element.Rated) * 100);
        }),
        backgroundColor: 'rgba(5, 148, 250, 0.2)',
        borderColor: this.rgbaColor,
        pointBackgroundColor: this.rgbaColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: this.rgbaColor,
      },
    ];
  }

  setBarChartData() {
    const filteredData = [];
    const filteredLabels = [];

    for (let i = 0; i < this.originalData.length; i++) {
      if (this.originalData[i].Rated !== null) {
        filteredData.push(Math.floor(Number(this.originalData[i].Rated) * 100));
        filteredLabels.push(this.originalLabels[i]);
      }
    }
    this.barChartLabels = filteredLabels;
    this.barChartData = [
      {
        data: filteredData,
        backgroundColor: 'rgba(5, 148, 250, 0.5)',
        borderColor: this.rgbaColor,
        hoverBackgroundColor: 'rgba(5, 148, 250, 0.7)',
        hoverBorderColor: this.rgbaColor,
      },
    ];
  }

  lateOrdersParticulars(monthAndYear: string) {
    this.router.navigate(['/performance/lateOrders'], {
      queryParams: {
        monthAndYear: monthAndYear,
        supplierCode: this.myForm.value.supplierCode,
      },
    });
  }

  pastThreeYears() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 2, currentYear - 1, currentYear];
  }

  setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
    setTimeout(() => {
      this.successMessage = undefined;
      this.errorMessage = undefined;
    }, 5000);
  }

  supplierNameChange() {
    const selectedSupplierCode = this.supplierCodeList.find(
      (element) => element.supplierName === this.myForm.value.supplierName
    )?.supplierCode;
    this.myForm.patchValue({
      supplierCode: selectedSupplierCode,
    });
  }

  supplierCodeChange() {
    const selectedSupplierName = this.supplierCodeList.find(
      (element) => element.supplierCode === this.myForm.value.supplierCode
    )?.supplierName;
    this.myForm.patchValue({
      supplierName: selectedSupplierName,
    });
  }
}
