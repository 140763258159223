import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  ReorderDocument,
  ReorderMap,
  ReorderSubmit,
} from 'src/app/core/model/manage-online-namc';
import { ManageOnlineTrainingService } from 'src/app/services/tmnaNamc/manage-online-training.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reorder-online-training',
  templateUrl: './reorder-online-training.component.html',
  styleUrls: ['./reorder-online-training.component.scss'],
})
export class ReorderOnlineTrainingComponent implements OnInit {
  documents: ReorderMap[] = [];
  category!: string;
  errorMessage: string | undefined;
  successMessage: string | undefined;
  selectedDocumentIndex: number | null = null;
  userId!: string;
  viewportHeight: number = window.innerHeight - 250;

  @HostListener('window:resize', [])
  onResize() {
    this.viewportHeight = window.innerHeight - 250;
  }

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly manageService: ManageOnlineTrainingService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {}
  ngOnInit() {
    this.ngxUiLoaderService.start('loader-01');
    this.category = this.router.url?.includes('/manage-namc-documents')
      ? 'N'
      : 'S';
    this.fetchReorderDocs();
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });
  }

  fetchReorderDocs() {
    this.manageService.reorderOnload(this.category)?.subscribe({
      next: (res: ReorderDocument) => {
        this.documents = res.documentMap;
        this.ngxUiLoaderService.stop('loader-01');
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.errorMessage = 'Something went wrong';
        setTimeout(() => {
          this.successMessage = undefined;
          this.errorMessage = undefined;
        }, 5000);
      },
    });
  }

  onCheckboxChange(index: number) {
    if (this.selectedDocumentIndex === index) {
      this.selectedDocumentIndex = null;
    } else {
      this.selectedDocumentIndex = index;
    }
  }

  moveUp() {
    if (this.selectedDocumentIndex !== null && this.selectedDocumentIndex > 0) {
      const index = this.selectedDocumentIndex;
      const temp = this.documents[index];
      this.documents[index] = this.documents[index - 1];
      this.documents[index - 1] = temp;
      this.selectedDocumentIndex--;

      if (this.selectedDocumentIndex) {
        setTimeout(() => {
          const elementToFocus = document.getElementById(`item-${index - 1}`);
          elementToFocus?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 0);
      }
    }
  }

  moveDown() {
    if (
      this.selectedDocumentIndex !== null &&
      this.selectedDocumentIndex < this.documents.length - 1
    ) {
      const index = this.selectedDocumentIndex;
      const temp = this.documents[index];
      this.documents[index] = this.documents[index + 1];
      this.documents[index + 1] = temp;
      this.selectedDocumentIndex++;

      if (this.selectedDocumentIndex) {
        setTimeout(() => {
          const elementToFocus = document.getElementById(`item-${index - 1}`);
          elementToFocus?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 0);
      }
    }
  }

  cancel() {
    window.history.back();
  }

  submit() {
    this.ngxUiLoaderService.start('loader-01');
    const documentIds: string[] = [];
    this.documents.forEach((element) => {
      documentIds.push(element.documentId?.toString());
    });

    const payload = {
      categoryType: this.category,
      documentList: documentIds,
      userId: this.userId,
    };

    this.manageService.saveReorder(payload)?.subscribe({
      next: (res: ReorderSubmit) => {
        this.manageService.setNotificationMessage(res?.result);
        if (this.category === 'N') {
          this.router.navigate(['/tmna-namc/manage-namc-documents']);
        } else {
          this.router.navigate(['/tmna-namc/manage-online-training']);
        }
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.errorMessage = 'Something went wrong';
        setTimeout(() => {
          this.successMessage = undefined;
          this.errorMessage = undefined;
        }, 5000);
      },
    });
  }
}
