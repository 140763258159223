import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MassEmailService } from 'src/app/services/suppliers/mass-email.service';

@Component({
  selector: 'app-send-supplier-notification',
  templateUrl: './send-supplier-notification.component.html',
  styleUrls: ['./send-supplier-notification.component.scss'],
})
export class SendSupplierNotificationComponent implements OnInit {
  form!: FormGroup;
  @ViewChild('fileUpload') myInputVariable: ElementRef | undefined;
  enableCC = false;
  enableFrom = false;
  faDelete = faTrash;
  uploadFileName: string[] = [];
  fileList: File[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(
    public dialogRef: MatDialogRef<SendSupplierNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly massEmailService: MassEmailService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      from: new FormControl({ value: '', disabled: true }, Validators.required),
      to: new FormControl({ value: '', disabled: true }),
      subject: ['', Validators.required],
      addCC: new FormControl(false),
      addFrom: new FormControl(false),
      cc: new FormControl({ value: '', disabled: true }),
      files: '',
      message: ['', Validators.required],
    });
    this.form.get('addCC')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('cc')?.enable();
      } else {
        this.form.get('cc')?.disable();
      }
    });
    this.form.get('addFrom')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('from')?.enable();
      } else {
        this.form.get('from')?.disable();
      }
    });
    this.form.patchValue({
      to: this.dataStore.selectedRow,
    });
  }

  close() {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (this.uploadFileName.length >= 5) {
      this.errorMessage = 'Maximum 5 Files Can Be Selected';
      return;
    }
    this.fileList.push(file);
    if (file) {
      this.uploadFileName.push(file.name);
    }
  }

  removeFile(fileName: string) {
    this.uploadFileName = this.uploadFileName.filter(
      (element) => element !== fileName
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendNotification();
    } else {
      this.form.markAllAsTouched();
    }
  }

  sendNotification() {
    const loaderVariable = 'send-supplier';
    const formData = new FormData();
    formData.append('selectedSuppId', this.dataStore.selectedSuppId);
    formData.append('selectedSuppName', this.dataStore.selectedSuppName ?? '');
    formData.append('emailIdsList', this.dataStore.selectedRow);
    formData.append('ccEmailIdsList', this.form.value.cc ?? '');
    formData.append('message', this.form.value.message);
    formData.append('emailSubject', this.form.value.subject);
    formData.append('emailFrom', this.form.value.from ?? '');
    formData.append('loggedInUserMail', this.dataStore.loggedInEmailId);
    const files: File[] = this.fileList;
    for (const i of files) {
      formData.append('files', i);
    }
    this.ngxUiLoaderService.start(loaderVariable);
    this.massEmailService.sendNotification(formData)?.subscribe({
      next: (result: any) => {
        if (result.success) {
          this.dialogRef.close(result.success);
        } else {
          this.errorMessage = result.error;
        }
        this.ngxUiLoaderService.stop(loaderVariable);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop(loaderVariable);
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }
}
