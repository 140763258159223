
<div class="addAttribute-btn">
  <button
    class="me-3 b2-button add"
    (click)="addAttribute()"
    style="float: right"
  >
    ADD ATTRIBUTE
  </button>
</div>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  [context]="context"
></app-grid>
