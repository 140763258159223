import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  faArrowsRotate,
  faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, filter } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { SelectedRole } from '../model/role-check.model';
import { User } from '../model/user.model';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  user: User | null = null;
  selectedRoles: SelectedRole | null = null;
  currentRole = '';
  roleSubject = '';
  recalculateSubscription!: Subscription;
  changeRoleText = '';
  enableMenu = false;
  isMenuOpen1 = false;
  isMenuOpen2 = false;
  isMenuOpen3 = false;
  isMenuOpen4 = false;
  isMenuOpen5 = false;
  isMenuOpen6 = false;

  faRefresh = faArrowsRotate;
  faLogout = faRightFromBracket;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly loginService: LoginService,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((value) => {
        if (value instanceof NavigationEnd) {
          this.initialize(value.url);
        }
      });
  }

  initialize(url: string) {
    this.userService.checkRoleSelected();

    this.getUserDetails();

    this.subscribeUserDetailsAndText(url);
  }

  getUserDetails() {
    this.ngxUiLoaderService.start('userFetch');
    this.userService.getUserDetails()?.subscribe((response: User) => {
      this.userService.setUserDetails(response);
      this.ngxUiLoaderService.stop('userFetch');
    });
  }

  subscribeUserDetailsAndText(url: string) {
    this.userService.userDetails?.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
        if (url === '/select-roles' || url === '/no-access') {
          this.enableMenu = false;
          this.changeRoleText = '';
        } else {
          this.enableMenu = true;
          this.changeRoleTextCalculator();
        }
      }
    });
  }

  loadRoleDetails() {
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      this.selectedRoles = selectedRoles;
      if (this.selectedRoles?.role === 'namc') {
        const role = this.user?.roles.find(
          (value) => value.namcCode === this.selectedRoles?.namc
        );
        this.currentRole = role?.roleDescription ?? '';
        this.roleSubject = role?.namcShortName ?? '';
        sessionStorage.setItem('roleName', this.currentRole);
      } else if (this.selectedRoles?.role === 'supplier') {
        sessionStorage.setItem('roleName', 'Supplier');
        this.supplierTitleText();
      }
    });
  }

  supplierTitleText() {
    this.currentRole = 'Supplier';
    this.roleSubject =
      (this.selectedRoles?.childSupplierCode === '-1'
        ? this.selectedRoles?.supplierCode
        : this.selectedRoles?.childSupplierCode) ?? '';
    if (this.selectedRoles?.childSupplierCode === '-1') {
      const supplierCode = this.selectedRoles.supplierCode ?? '';
      if (supplierCode.length === 6) {
        this.roleSubject = supplierCode.substring(2);
      } else {
        this.roleSubject = supplierCode;
      }
    } else {
      this.roleSubject = this.selectedRoles?.childSupplierCode ?? '';
    }
  }

  changeRoleTextCalculator() {
    const isSupplier =
      this.user?.roles.find((value) => value.roleDescription === 'Supplier') !==
      undefined;
    const isNamc =
      this.user?.roles.find((value) => value.roleDescription !== 'Supplier') !==
      undefined;
    if (isSupplier && isNamc) {
      if (this.selectedRoles?.role === 'namc') {
        this.changeRoleText = 'CHANGE ROLE / NAMC';
      } else {
        this.changeRoleText = 'CHANGE ROLE / SUPPLIER CODE';
      }
    } else if (isSupplier) {
      this.changeRoleText = 'CHANGE SUPPLIER CODE';
    } else if (isNamc) {
      this.changeRoleText = 'CHANGE NAMC';
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.userService.clearUserDetails();
    this.userService.clearRoleSelected();
    this.loginService.logout();
  }

  changeRole() {
    this.userService.setCancelButtonValue();
    this.router.navigate(['select-roles']);
  }

  openMenu1(menuTrigger1: MatMenuTrigger): void {
    menuTrigger1.openMenu();
    this.isMenuOpen1 = true;  // Set state to true when menu opens
  }

  closeMenu1(): void {
    this.isMenuOpen1 = false; // Reset state when the menu is closed
  }
  openMenu2(menuTrigger2: MatMenuTrigger): void {
    menuTrigger2.openMenu();
    this.isMenuOpen2 = true;  // Set state to true when menu opens
  }

  closeMenu2(): void {
    this.isMenuOpen2 = false; // Reset state when the menu is closed
  }
  openMenu3(menuTrigger3: MatMenuTrigger): void {
    menuTrigger3.openMenu();
    this.isMenuOpen3 = true;  // Set state to true when menu opens
  }

  closeMenu3(): void {
    this.isMenuOpen3 = false; // Reset state when the menu is closed
  }
  openMenu4(menuTrigger4: MatMenuTrigger): void {
    menuTrigger4.openMenu();
    this.isMenuOpen4 = true;  // Set state to true when menu opens
  }

  closeMenu4(): void {
    this.isMenuOpen4 = false; // Reset state when the menu is closed
  }
  openMenu5(menuTrigger5: MatMenuTrigger): void {
    menuTrigger5.openMenu();
    this.isMenuOpen5 = true;  // Set state to true when menu opens
  }

  closeMenu5(): void {
    this.isMenuOpen5 = false; // Reset state when the menu is closed
  }
  openMenu6(menuTrigger6: MatMenuTrigger): void {
    menuTrigger6.openMenu();
    this.isMenuOpen6 = true;  // Set state to true when menu opens
  }

  closeMenu6(): void {
    this.isMenuOpen6 = false; // Reset state when the menu is closed
  }

}
