import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import {
  ContactListMap,
  SupplierListMap,
} from 'src/app/core/model/contacts.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  contactsDropDown() {
    return this.http
      .get<SupplierListMap[]>(`${this.baseApiUrl}/suppliercodes`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  loadContactDetails(payload: any) {
    return this.http
      .post<ContactListMap>(`${this.baseApiUrl}/supplier/contact`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
}
