<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">
    <fa-icon class="me-2" [icon]="faWarning"></fa-icon>
    Delete Attribute
  </h2>
  <hr id="shadow" />
  <br />
  <app-notification-message
    [errorMessage]="errorMessage"
    [successMessage]="successMessage"
  >
  </app-notification-message>
  <div>
    <p class="roboto-bold text-center paragraph">
      Are you sure you want to delete this attribute?
    </p>
  </div>
  <div class="right-align mt-2">
    <button (click)="deleteAttribute()" class="b2-button add roboto-medium">
      DELETE
    </button>
    <button (click)="closeDialog()" class="b2 me-3 ms-3 roboto-bold">
      CANCEL
    </button>
  </div>
</div>
