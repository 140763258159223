<nav class="navbar navbar-expand-md navbar-light ps-4">
  <div class="heading-class">
    <img
      src="./assets/images/toyota-logo.png"
      class="nlogo"
      alt="Toyota Logo"
    />
  </div>
  <div class="title_taskmanager">
    <span>TPROMS</span>
  </div>
  <div class="carousel-navbar col-7 d-flex align-items-center">
    <div class="carousel-wrapper" #carouselWrapper>
      <ul class="menu-items" style="transition: all 0.2s ease">
        <li class="home" routerLinkActive="active">
          <div class="d-flex align-items-center" mat-button>
            <mat-icon class="home-icon" routerLink="/home">home</mat-icon>
          </div>
        </li>
        <span class="divider" *ngIf="enableMenu"></span>
        <li  [ngClass]="{ 'open-menu1': isMenuOpen1 }" 
        routerLinkActive="active" *ngIf="enableMenu" (click)="openMenu1(menuTrigger1)" (keyPress)="openMenu1(menuTrigger1)">
          <div
            class="d-flex align-items-center event-order"
            mat-button
            [matMenuTriggerFor]="belowMenu"
            #menuTrigger1="matMenuTrigger"
          >
            EVENTS & ORDERS
            <mat-icon>{{ isMenuOpen1 ? 'expand_less' : 'expand_more' }}</mat-icon>
            <mat-menu #belowMenu="matMenu" yPosition="below" (closed)="closeMenu1()">
              <div
                class="links"
                routerLink="/event-order/manage-trial-events"
                *ngIf="currentRole !== 'Supplier'"
              >
                MANAGE TRIAL EVENTS
              </div>
              <div class="links" routerLink="/event-order/trial-orders">
                TRIAL ORDERS
              </div>
              <div
                class="links"
                routerLink="/supplier/online-training-namc-documents"
                *ngIf="currentRole === 'Supplier'"
              >
                NAMC DOCUMENTS
              </div>
              <div
                class="links"
                routerLink="/event-order/uploadOrders"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                UPLOAD ORDERS
              </div>
              <div
                class="links"
                routerLink="/event-order/manage-trial-events"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                UPLOAD UPDATED ORDERS
              </div>
              <div class="links" routerLink="/event-order/archived-orders">
                ARCHIVED ORDERS DATA
              </div>
              <div
                class="links"
                routerLink="/event-order/upload-orders-data"
                *ngIf="currentRole === 'Supplier'"
              >
                UPLOAD ORDERS
              </div>

            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole !== 'Supplier'"
        ></span>
        <li
        [ngClass]="{ 'open-menu2': isMenuOpen2 }" 
          routerLinkActive="active"
          *ngIf="enableMenu && currentRole !== 'Supplier'"
          (click)="openMenu2(menuTrigger2)" (keyPress)="openMenu2(menuTrigger2)"
        >
          <div
            class="d-flex align-items-center supplier-menu"
            mat-button
            [matMenuTriggerFor]="belowMenu2"
            #menuTrigger2="matMenuTrigger"
          >
            SUPPLIERS<mat-icon>{{ isMenuOpen2 ? 'expand_less' : 'expand_more' }}</mat-icon>
            <mat-menu #belowMenu2="matMenu" yPosition="below" (closed)="closeMenu2()">
              <div
                class="links"
                routerLink="/performance"
                *ngIf="
                  currentRole === 'IS Admin' ||
                  currentRole === 'TMNA Admin' ||
                  currentRole === 'NAMC Admin'
                "
              >
                PERFORMANCE
              </div>
              <div class="links" routerLink="/contacts">CONTACTS</div>
              <div class="links" routerLink="/supplier/online-training">ONLINE TRAINING</div>
              <div class="links" routerLink="/supplier/namc-documents">NAMC DOCUMENTS</div>
              <div
                class="links"
                routerLink="/supplier/loadMassEmail"
                *ngIf="
                  currentRole === 'IS Admin' ||
                  currentRole === 'TMNA Admin' ||
                  currentRole === 'NAMC Admin'
                "
              >
                MASS EMAIL
              </div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="
            enableMenu &&
            (currentRole === 'IS Admin' ||
              currentRole === 'TMNA Admin' ||
              currentRole === 'NAMC Admin')
          "
        ></span>
        <li
          routerLinkActive="active"
          [ngClass]="{ 'open-menu3': isMenuOpen3 }"
          *ngIf="
            enableMenu &&
            (currentRole === 'IS Admin' ||
              currentRole === 'TMNA Admin' ||
              currentRole === 'NAMC Admin')
          "
          (click)="openMenu3(menuTrigger3)" (keyPress)="openMenu3(menuTrigger3)"
        >
          <div
            class="d-flex align-items-center tmna-menu"
            mat-button
            [matMenuTriggerFor]="belowMenu3"
            #menuTrigger3="matMenuTrigger"
          >
            TMNA/NAMC<mat-icon>{{ isMenuOpen3 ? 'expand_less' : 'expand_more' }}</mat-icon>
            <mat-menu #belowMenu3="matMenu" yPosition="below" (closed)="closeMenu3()">
              <div class="links" routerLink="/tmna-namc/manage-users">
                MANAGE USER
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-online-training"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                MANAGE ONLINE TRAINING
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-namc-documents"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                MANAGE NAMC DOCUMENTS
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-attributes"
                *ngIf="currentRole === 'IS Admin'"
              >
                MANAGE ATTRIBUTES
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-configurations"
                *ngIf="currentRole === 'IS Admin'"
              >
                MANAGE CONFIGURATIONS
              </div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        ></span>
        <li
          routerLinkActive="active"
          [ngClass]="{ 'open-menu4': isMenuOpen4 }"
          *ngIf="enableMenu && currentRole === 'Supplier'"
          (click)="openMenu4(menuTrigger4)" (keyPress)="openMenu4(menuTrigger4)"
        >
          <div
            class="d-flex align-items-center"
            mat-button
            [matMenuTriggerFor]="belowMenu4"
            #menuTrigger4="matMenuTrigger"
          >
            REFERENCES<mat-icon>{{ isMenuOpen4 ? 'expand_less' : 'expand_more' }}</mat-icon>
            <mat-menu #belowMenu4="matMenu" yPosition="below" (closed)="closeMenu4()">
              <div class="links" routerLink="/performance">PERFORMANCE</div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        ></span>
        <li
          routerLinkActive="active"
          [ngClass]="{ 'open-menu5': isMenuOpen5 }"
          *ngIf="enableMenu && currentRole === 'Supplier'"
          (click)="openMenu5(menuTrigger5)" (keyPress)="openMenu5(menuTrigger5)"
        >
          <div
            class="d-flex align-items-center"
            mat-button
            [matMenuTriggerFor]="belowMenu5"
            #menuTrigger5="matMenuTrigger"
          >
            HELP<mat-icon>{{ isMenuOpen5 ? 'expand_less' : 'expand_more' }}</mat-icon>
            <mat-menu #belowMenu5="matMenu" yPosition="below" (closed)="closeMenu5()">
              <div class="links" routerLink="/routingtest">ONLINE TRAINING</div>
            </mat-menu>
          </div>
        </li>
        <span class="divider"></span>
      </ul>
    </div>
  </div>

  <div class="user" *ngIf="user !== null" (click)="openMenu6(menuTrigger6)" 
  (keyPress)="openMenu6(menuTrigger6)"  [ngClass]="{ 'open-menu6': isMenuOpen6 }">
    <div class="name_role">
      <span
        class="d-flex align-items-center"
        [ngClass]="{ 'open-menu6': isMenuOpen6 }"
        mat-button
        [matMenuTriggerFor]="belowMenu8"
        #menuTrigger6="matMenuTrigger"
        >{{ user.userId.toUpperCase() }}
        <mat-icon class="mx-2 drop-ico">{{ isMenuOpen6? 'expand_less' : 'expand_more' }}</mat-icon>
        <mat-menu class="mat-menu-panel-3" #belowMenu8="matMenu" xPosition="before" (closed)="closeMenu6()">
          <div mat-menu-item [disabled]="true" class="user-links adjust-height" >
            <mat-icon style="color: #000000de; opacity: 1">person</mat-icon>
            <span  style="color: #000000de; opacity: 1" class="roboto-bold"
              >{{ user.userId.toUpperCase() }}</span>
              <p class="roboto-bold adjust-item">{{ currentRole ?   currentRole : "" }}</p
            >
          </div>
          <div
            mat-menu-item
            (click)="changeRole()"
            (keydown)="changeRole()"
            *ngIf="changeRoleText !== ''"
            class="user-links"
          >
            <mat-icon>
              <fa-icon [icon]="faRefresh"></fa-icon>
            </mat-icon>
            <span class="roboto-bold">{{ changeRoleText }}</span>
          </div>
          <div
            mat-menu-item
            (click)="logout()"
            (keydown)="logout()"
            class="user-links"
          >
            <mat-icon>
              <fa-icon [icon]="faLogout"></fa-icon>
            </mat-icon>
            <span class="roboto-bold">LOGOUT</span>
          </div>
        </mat-menu>
      </span>
      <div>
        <span style="color: #D10000; font-weight: bold"
          >{{ currentRole
          }}{{ roleSubject ? " (" + roleSubject + ")" : "" }}</span
        >
      </div>
    </div>
  </div>
</nav>
