
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
></app-notification-message>
<form [formGroup]="addAttributeForm">
  <div class="d-flex mt-4 col-4">
    <div class="d-flex mx-4" style="flex-direction: column">
      <span class="bold-label required-color header-display px-2 mb-2"
        >ATTRIBUTE TYPE</span
      >
      <p-dropdown
        [options]="attributeTypeList"
        formControlName="addAttType"
        optionLabel="label"
        optionValue="code"
        class="dropdownOptions"
        placeholder="Enter Attribute Type"
      >
      </p-dropdown>
    </div>
  </div>
  <div
    class="d-flex mx-4"
    *ngIf="
      this.addAttributeForm.controls['addAttType'].invalid &&
      (this.addAttributeForm.controls['addAttType'].dirty ||
        this.addAttributeForm.controls['addAttType'].touched)
    "
  >
    <small
      class="error col-4"
      *ngIf="this.addAttributeForm.controls['addAttType'].hasError('required')"
    >
      Please provide Attribute Type
    </small>
  </div>
  <div class="d-flex mt-4 col-4">
    <div class="d-flex mx-4" style="flex-direction: column">
      <span class="bold-label required-color header-display px-2 mb-2"
        >ATTRIBUTE VALUE</span
      >
      <input
        type="text"
        for="attributeValue"
        size="100"
        formControlName="addAttValue"
        placeholder="Enter Attribute Value"
        maxlength="100"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div
    class="d-flex mx-4"
    *ngIf="
      this.addAttributeForm.controls['addAttValue'].invalid &&
      (this.addAttributeForm.controls['addAttValue'].dirty ||
        this.addAttributeForm.controls['addAttValue'].touched)
    "
  >
    <small
      class="error col-4"
      *ngIf="this.addAttributeForm.controls['addAttValue'].hasError('required')"
    >
      Please provide Attribute Value
    </small>
  </div>
  <div class="d-flex mt-4 col-4">
    <div class="d-flex mx-4" style="flex-direction: column">
      <span class="required-color bold-label header-display px-2 mb-2"
        >ATTRIBUTE DESCRIPTION</span
      >
      <input
        type="text"
        for="attributeDescription"
        size="100"
        formControlName="addAttDesc"
        placeholder="Enter Attribute Description"
        maxlength="70"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div
    class="d-flex mx-4"
    *ngIf="
      this.addAttributeForm.controls['addAttDesc'].invalid &&
      (this.addAttributeForm.controls['addAttDesc'].dirty ||
        this.addAttributeForm.controls['addAttDesc'].touched)
    "
  >
    <small
      class="error col-4"
      *ngIf="this.addAttributeForm.controls['addAttDesc'].hasError('required')"
    >
      Please provide Attribute Description
    </small>
  </div>
</form>
<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button
      class="b2-button add"
      [disabled]="addAttributeForm.invalid"
      (click)="addAttribute()"
    >
      ADD ATTRIBUTE
    </button>
  </div>
</footer>
