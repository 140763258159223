import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { SupplierContactData, SupplierContactPayload, SupplierMapList } from 'src/app/core/model/supplier-contact';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MassEmailService {

  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  massEmailDropDown() {
    return this.http
      .get<SupplierMapList[]>(`${this.baseApiUrl}/suppliercodes`,
      {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  loadGridDetails(payload: SupplierContactPayload) {
    return this.http
      .post<SupplierContactData>(
        `${this.baseApiUrl}/supplier/contact`,
        payload,
        {
          withCredentials: true,
        }
      )
      .pipe(catchError(handleError));
  }

  sendNotification(formdata: FormData){
    return this.http
      .post(
        `${this.baseApiUrl}/email`,
        formdata,
        {
          withCredentials: true,
        }
      )
      .pipe(catchError(handleError));
  }
}
