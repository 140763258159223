import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrderDetails } from 'src/app/core/model/trial-order.model';
import { ArchiveOrdersService } from 'src/app/services/event-and-orders/archive-orders.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-archived-orders',
  templateUrl: './archived-orders.component.html',
  styleUrls: ['./archived-orders.component.scss'],
})
export class ArchivedOrdersComponent {
  eventForm!: FormGroup;
  archiveOrders: TrialOrderDetails[] = [];
  errorMessage: string | undefined = undefined;
  successMessage: string | undefined = undefined;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly archiveOrderService: ArchiveOrdersService,
    private readonly exportOrderService: ExcelService,
    private readonly router: Router
  ) {
    this.eventForm = this.fb.group({
      projectCode: [''],
      eventName: [''],
      partNumber: [''],
    });
  }

  ngOnInit(): void {
    //No work
  }

  onSubmit(): void {
    this.ngxUiLoaderService.start('archiveOrders');
    this.archiveOrderService
      .getArchiveData(
        this.eventForm.controls['projectCode'].value.toUpperCase(),
        this.eventForm.controls['eventName'].value.toUpperCase(),
        this.eventForm.controls['partNumber'].value.toUpperCase()
      )
      ?.subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('archiveOrders');
          this.archiveOrders = result.archiveOrders as TrialOrderDetails[];
          this.exportArchiveOrders(this.archiveOrders);
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('archiveOrders');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error.error;
          this.settingTimeOut();
        },
      });
  }

  exportArchiveOrders(data: TrialOrderDetails[]) {
    const headers = [
      'NAMC',
      'Project Code',
      'Event Name',
      'Order Tracking No',
      'Supplier Code',
      'Supplier Name',
      'Part Number',
      'Part Name',
      'Issue Date',
      'Quantity',
      'NAMC Due Date',
      'Approved Supplier Ship Date',
      'Completion Date',
      'PO Tracking Number',
      'Manifest Number',
      'Receiver Number',
      'Order Status',
      'Order Type',
      'SAP Status',
      'PSMS Routing',
      'Specialist Name',
      'Kanban Number',
      'Revision Date',
      'Supplier Pickup Date',
      'NAMC Comment',
      'Supplier Comment',
      'Store Address',
      'Line Side Address',
      'Dock Code',
      'Pallet Code',
      'ECI Number',
      'ECI Revision',
      'Specialist Code',
      'Shipping Date',
      'Trailer Number',
      'Shipping Carrier',
      'Tracking/Airbill Number',
      'Life Cycle Code',
      'Supplier Ship Date Change Request',
      'Supplier Ship Date Change Comment',
      'OWK Label Line1',
      'OWK Label Line2',
      'Scan Comment',
      'Send To SAP Flag',
      'PO Line No',
      'Ariba PR No',
      'Ariba Order Status',
      'Ariba Order Comment',
      'NA Pipe Status',
    ];
    this.exportFinalArchiveOrders(headers, data);
  }

  exportFinalArchiveOrders(headers: string[], data: TrialOrderDetails[]) {
    const exportData: any[] = [];
    data?.forEach((e) => {
      const obj = {
        NAMC: e.namcCode,
        PROJECT_CODE: e.projectCode,
        EVENT_NAME: e.eventName,
        ORDER_TRACKING: e.orderNumber,
        SUPP_CODE: e.supplierCd,
        SUPPLIER_NAME: e.supplierName,
        PART_NUMBER: e.partNumber,
        PART_NAME: e.partName,
        ISSUE_DATE: e.createTimestamp,
        QUANTITY: e.totalQty,
        NAMC_DUE_DATE: e.orderDueDate,
        APPROVED_SUPPLIER_SHIP_DATE: e.promiseDate,
        COMPLETION_DATE: e.completionString,
        PO_TRACKING_NUMBER: e.poTrackingNumber,
        MANIFEST_NUMBER: e.manifestNumber,
        RECEIVER_NUMBER: e.orderReceiverNbr,
        ORDER_STATUS: e.status,
        ORDER_TYPE: e.orderType,
        SAP_STATUS: e.sapStatus,
        PSMS_ROUTING: e.psmsRouting,
        SPECIALIST_NAME: e.specialistName,
        KANBAN_NUMBER: e.kanbanNumber,
        REVISION_DATE: e.revisionDate,
        SUPPLIER_PICKUP_DATE: e.supplierPickupDate,
        NAMC_COMMENT: e.namcComment,
        SUPPLIER_COMMENT: e.supplierComment,
        STORE_ADDRESS: e.storeAddress,
        LINESIDE_ADDRESS: e.lineSideAddress,
        DOCK_CODE: e.dockCode,
        PALLET_CODE: e.palletCode,
        ECI_NUMBER: e.eciNumber,
        ECI_REVISION: e.eciRevision,
        SPECIALIST_CODE: e.specialistCd,
        SHIPPING_DATE: e.shippingDateTime,
        TRAILER_NUMBER: e.trailerNumber,
        SHIPPING_CARRIER: e.shippingCarrier,
        TRACKING_AIRBILL_NUMBER: e.trackingAirbillNumber,
        LIFE_CYCLE_CODE: e.lifeCycleCode,
        SUPP_SHP_DT_CHNG_REQ: e.requestForSuppShipDtChng,
        SUPP_SHP_DT_CHNG_COMM: e.requestForSuppShipcmmntChng,
        OWK_LABEL_LINE1: e.owkLabelLine1,
        OWK_LABEL_LINE2: e.owkLabelLine2,
        SCAN_COMMENT: e.scanComment,
        SEND_TO_SAP_FLAG: e.sendToSapFlag,
        PO_LINE_NO: e.poLineNo,
        ARIBA_PR_NO: e.aribaPrNumber,
        ARIBA_ORDER_STATUS: e.aribaOrderStatus,
        ARIBA_ORDER_COMMENT: e.aribaOrderComment,
        NA_PIPE_STATUS: e.naPipeStatus,
      };
      exportData.push(obj);
    });
    this.exportOrderService.exportAsExcelFile(
      exportData,
      this.generateFileName(),
      headers
    );
  }

  getCurrentDate(): Date {
    return new Date();
  }

  generateFileName() {
    const now = this.getCurrentDate();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${month}${day}${year}`;
    const hours = String((now.getHours() + 24) % 12 || 12).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;
    return `Archive_Data_${formattedDate}_${formattedTime}`;
  }

  cancel() {
    this.router.navigate(['/home']);
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
