
<div class="event">
  <span class="required-color px-1"></span>
  <label for="event">Event</label><br />
  <p-dropdown
    [options]="eventList"
    optionLabel="eventName"
    optionValue="eventId"
    class="dropdownOptions"
    placeholder="(Select)"
    styleClass="w-50"
    (onChange)="eventDropdownChange($event)"
  >
  </p-dropdown
  ><br />
  <small *ngIf="showError" style="color: red">This Field Is Required</small>
  <p *ngIf="allSelection">
    Warning: Only limited functions will be available on the next screen by
    selecting ALL.
  </p>
  <p *ngIf="deletedEvent">
    Warning: Only export function will be available for deleted events on the next screen.
  </p>
</div>
<div class="create_footer fixed-bottom">
  <button
    class="black-button blackbtnalign me-3 mt-2"
    (click)="displayTrialOrder()"
  >
    DISPLAY TRIAL ORDER
  </button>
  <button
    class="btn-table blackbtnalign me-3 mt-2"
    *ngIf="selectedRole.role === 'namc'"
    [disabled]="!isBtnEnable"
    (click)="displayHistory()"
  >
    DISPLAY HISTORY
  </button>
  <button
    class="btn-table blackbtnalign me-3 mt-2"
    *ngIf="selectedRole.role === 'namc'"
    [disabled]="!isBtnEnable"
  >
    DOWNLOAD CURRENT ORDER STATUS TEMPLATE
  </button>
  <button
    class="btn-table blackbtnalign me-3 mt-2"
    *ngIf="selectedRole.role === 'namc'"
    [disabled]="!isBtnEnable"
  >
    DOWNLOAD BLANK ORDER TEMPLATE
  </button>
  <button
    class="btn-table blackbtnalign me-3 mt-2"
    *ngIf="selectedRole.role === 'supplier'"
    [disabled]="!isBtnEnable"
    (click)="displayShippingAddress()"
  >
    DISPLAY ORDER SHIPPING ADDRESS
  </button>
</div>
