import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageTrialEventsService } from 'src/app/services/events-and-orders/manage-trial-events.service';

@Component({
  selector: 'app-delete-event',
  templateUrl: './delete-event.component.html',
  styleUrls: ['./delete-event.component.scss'],
})
export class DeleteEventComponent implements OnInit {
  faWarning = faTriangleExclamation;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  constructor(
    public dialogRef: MatDialogRef<DeleteEventComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dataStore: { eventId: number; updatedBy: string | undefined, eventName: string },
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly deleteService: ManageTrialEventsService
  ) {}

  ngOnInit(): void {
    //No work
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteEvent() {
    //Now work
    this.ngxUiLoaderService.start('deleteEvent');
    this.deleteService
      .deleteEventService(this.dataStore.eventId, this.dataStore.updatedBy)
      .subscribe({
        next: (_result) => {
          this.ngxUiLoaderService.stop('deleteEvent');
          this.dialogRef.close('Success');
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('deleteEvent');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error;
          this.settingTimeOut();
        },
      });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
