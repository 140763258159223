import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { Events } from 'src/app/core/model/events.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadOrdersService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  summonEvents() {
    return this.http
      .get<Events[]>(`${this.baseApiUrl}/events-orders/events`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  submitOrders(payloadObj: any) {
    return this.http
      .post<{ result: string }>(
        `${this.baseApiUrl}/supplier/uploadOrder`,
        payloadObj,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  submitUpdatedOrders(
    eventId: string,
    eventTypeId: string | undefined,
    file: File,
    userId: string
  ) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<{ result: string; errorList: string[] }>(
        `${this.baseApiUrl}/updateOrder?trialEventId=${eventId}&eventTypeId=${eventTypeId}&userId=${userId}`,
        formData,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
