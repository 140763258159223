import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchiveOrdersService {
  baseApiUrl: string;


  constructor(private readonly http: HttpClient) { 
    this.baseApiUrl = environment.baseAPIURL;
  }

  getArchiveData(projectCode: string, eventName: string, partNumber: string){
    return this.http
      .get<any>(
        `${this.baseApiUrl}/events-orders/orders/archive?projectCode=${projectCode}&eventName=${eventName}&partNumber=${partNumber}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
