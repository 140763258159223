import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DropdownData } from 'src/app/core/model/dropdown-data.model';
import { AddNewAttribute } from 'src/app/core/model/manage-attributes.model';
import { User } from 'src/app/core/model/user.model';
import { ManageAttributesService } from 'src/app/services/tmna-namc/manage-attributes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-attribute',
  templateUrl: './add-attribute.component.html',
  styleUrls: ['./add-attribute.component.scss'],
})
export class AddAttributeComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  userId = '';
  user: User | null = null;
  attributeTypeList: DropdownData[] = [];
  public addAttributeForm: FormGroup = {} as FormGroup;
  requiredDisplayCheck = true;

  constructor(
    private readonly router: Router,
    public formbuilder: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly attributeService: ManageAttributesService,
    private readonly userService: UserService
  ) {
    this.addAttributeForm = this.formbuilder.group({
      addAttType: ['', Validators.required],
      addAttValue: ['', Validators.required],
      addAttDesc: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadAttributeTypeDropdown();
    this.userService.userDetails.subscribe({
      next: (value) => {
        if (value !== null) {
          this.user = value;
          this.userId = this.user.userId;
        }
      },
    });
  }

  cancel() {
    this.router.navigate(['/tmna-namc/manage-attributes']);
  }

  loadAttributeTypeDropdown() {
    this.ngxUiLoaderService.start('loadAttributeType');
    this.attributeService.getAttributeTypeList().subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('loadAttributeType');
        result.forEach((value) => {
          if(value.attributeType && value.attributeType.trim() !== ''){
          this.attributeTypeList.push({
            code: value.attributeType,
            label: value.attributeType,
          });
          this.attributeTypeList.sort((a, b) => a.label.localeCompare(b.label));
        }
        });
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('loadAttributeType');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  addAttributePayload() {
    const data: AddNewAttribute = {
      attributeType: this.addAttributeForm.controls['addAttType'].value,
      attributeValue: this.addAttributeForm.controls['addAttValue'].value,
      attributeDescription: this.addAttributeForm.controls['addAttDesc'].value,
      userId: this.userId,
      isEditable: 'Y',
    };
    return data;
  }

  addAttribute() {
    this.addAttributeForm.markAllAsTouched();
    const addAttributeData = this.addAttributePayload();
    this.ngxUiLoaderService.start('addAttribute');
    this.attributeService.addNewAttribute(addAttributeData).subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('addAttribute');
        this.attributeService.setMessage(`New Attribute '${this.addAttributeForm.controls['addAttValue'].value}' Added Successfully.`);
        this.router.navigate(['/tmna-namc/manage-attributes']);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('addAttribute');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
