import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from 'src/app/core/model/user.model';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';
import { UserService } from 'src/app/services/user.service';
import axios from 'axios';
@Component({
    selector: 'app-upload-orders-data',
    templateUrl: './upload-orders-data.component.html',
    styleUrls: ['./upload-orders-data.component.scss']
})
export class UploadOrdersDataComponent implements OnInit {
    eventForm!: FormGroup;
    uploadedFileName: string | null = null;
    events: any = [];
    hideForm: boolean = false;
    errorMessage: any = null;
    successMessage: any = null;
    user: User|undefined;
    userId = '';
  eventid: any;
    constructor(
        private readonly fb: FormBuilder,
        private readonly ngxUiLoaderService: NgxUiLoaderService,
        private readonly uploadOrdersService: UploadOrderSupplierService,
        private readonly userService: UserService
    ) { }

    ngOnInit(): void {
        this.userService.userDetails.subscribe((value) => {
            if (value !== null) {
              this.user = value;
              this.userId=value.userId
            }
          });
        this.eventForm = this.fb.group({
            event: ['', Validators.required],
            eventDescription: [{value:'', disabled: true }],
            eventType: [{value:'', disabled: true }],
            projectCode: [{value:'', disabled: true }],
            file: [null, Validators.required],
        });
        this.eventForm.get('eventDescription')?.disable();
        this.eventForm.get('eventType')?.disable();
        this.eventForm.get('projectCode')?.disable();
        this.summonEvents();
    }

    summonEvents() {
        this.uploadOrdersService.summonEvents().subscribe({
            next: (response) => {
              
              this.events = response; 
              console.log(this.events)
            },
            error: (error) => {
              this.errorMessage = 'Failed to fetch events. Please try again later.';
            },
          });
    }
    setDetails(event: any): void {
        const selectedEventId = (event.target as HTMLSelectElement).value;

        const selectedEvent = this.events.find(
          (event: { eventId: number; }) => event.eventId === +selectedEventId
         
        );
    this.eventid=selectedEvent.eventId
    console.log(this.eventid)
        if (selectedEvent) {
          // Patch form controls with selected event data
          this.eventForm.patchValue({
            eventDescription: selectedEvent.eventDesc,
            eventType: selectedEvent.eventType,
            projectCode: selectedEvent.projectCode,
            eventid:selectedEvent.eventId
          });
          this.eventForm.get('eventDescription')?.disable();
          this.eventForm.get('eventType')?.disable();
          this.eventForm.get('projectCode')?.disable();
        }
      }
    
    onFileChange(event: any) {
        const file = event.target.files[0];
        if (file) {
            this.uploadedFileName = file.name;
            this.eventForm.patchValue({
                file: file,
            });
        }
    }

    removeFile() {
        this.uploadedFileName = null;
        this.eventForm.patchValue({
            file: null,
        });
    }

    showForm(event: any): void {
        this.eventForm.reset();
        this.hideForm = true;
        this.uploadedFileName = null;

        this.eventForm.get('eventDescription')?.disable();
        this.eventForm.get('eventType')?.disable();
        this.eventForm.get('projectCode')?.disable();
      }


    selectedOption: string = '';
    options = [
        { value: '1', name: 'Upload ECI Data' },
        { value: '2', name: 'Upload Label Request' },
        { value: '3', name: 'Upload Shipping Info' }
    ];
    buttonTextMapping: any = {
        '1': 'Upload ECI Data',
        '2': 'Upload Label Request',
        '3': 'Upload Orders File'
    };
    // Button text based on selected option
    get buttonText(): string {
        return this.buttonTextMapping[this.selectedOption] || 'Default Text';
    }
    onSubmit(): void {
        if (this.eventForm?.valid && this.uploadedFileName) {
            this.ngxUiLoaderService.start(); // Start loader
        
            const folderMapping: { [key: string]: string } = {
              '1': 'uploadeci',
              '2': 'uploadlabel',
              '3': 'uploadshipping',
            };
        
            const folderName = folderMapping[this.selectedOption];
            const file = this.eventForm.get('file')?.value; // Uploaded file
            if (folderName && file) {
                const originalFileName = file.name; 
                const fileType = originalFileName.substring(originalFileName.lastIndexOf('.') + 1);
                const fileName = `${folderName}/${originalFileName}`; 
        console.log(fileName,fileType)
              this.uploadOrdersService.uploadFile(fileName, fileType).subscribe({
                next: (response) => {
                  console.log(response)
                     axios.put(response.url, fileName, {
                      headers: {
                
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      },
                    });
                    const payload = {
                        eventid:  this.eventid, 
                        userid: this.userId,
                        filename:fileName, 
                      };
                    this.successMessage = 'File uploaded successfully!';
                    this.apiCall(payload);
                },
                error: (err) => {
                  this.errorMessage = 'Failed to generate upload URL.';
           
                },
              });
            }
          } else {
            this.eventForm.markAllAsTouched();
          }
        }
  apiCall(payload:any){
    switch (this.selectedOption) {
        case '1': // ECI
          this.uploadOrdersService.callEciApi(payload).subscribe({
            next: (response: any) => {
              console.log('ECI API response:', response);
            },
            error: (err: any) => {
              
              this.errorMessage = 'Failed to generate upload URL.';
       
            },
          });
          break;
        case '2': // Label
          this.uploadOrdersService.callLabelApi(payload).subscribe({
            next: (response: any) => {
              console.log('Label API response:', response);
            },
            error: (err:any) => {           
              this.errorMessage = 'Failed to generate upload URL.';
            },
          });  
          break;
        case '3': // Shipping
          this.uploadOrdersService.callShippingApi(payload).subscribe({
            next: (response: any) => {
              console.log('Shipping API response:', response);
            },
            error: (err:any) => {
              this.errorMessage = 'Failed to generate upload URL.';   
            },
          });
        
          break;

      }
    }
    
}