import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SendSupplierNotificationComponent } from 'src/app/core/dialogs/send-supplier-notification/send-supplier-notification.component';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import {
  SupplierContact,
  SupplierContactData,
  SupplierMapList,
} from 'src/app/core/model/supplier-contact';
import { User } from 'src/app/core/model/user.model';
import { ExcelService } from 'src/app/services/excel.service';
import { MassEmailService } from 'src/app/services/suppliers/mass-email.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-mass-email',
  templateUrl: './mass-email.component.html',
  styleUrls: ['./mass-email.component.scss'],
})
export class MassEmailComponent implements OnInit {
  myForm: FormGroup;
  selectedSupplierName!: string;
  supplierNameList: string[] = [];
  selectedSupplierCode!: string;
  supplierCodeList: SupplierMapList[] = [];
  selectedRow: SupplierContact[] = [];
  displayRowData: SupplierContact[] = [];
  columnDefs: ColDef[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  rowSelection: 'single' | 'multiple' | undefined = 'multiple';
  showGrid = false;
  loggedInEmailId = '';
  user: User | undefined = undefined;
  multipleTo: string[] = [];
  emailsString = '';
  loaderVariable = 'mass-email';
  selectedRole: { namc: string };

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly massEmailService: MassEmailService,
    private readonly userService: UserService,
    private readonly excelService: ExcelService
  ) {
    this.myForm = this.fb.group({
      supplierCode: ['', Validators.required],
      supplierName: ['', Validators.required],
      customSupplierCode: [''],
    });
    this.selectedRole = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
  }

  ngOnInit(): void {
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.user = value;
        this.loggedInEmailId = value.emailId;
      }
    });
    this.getColDefs();
    this.fetchSupplierDetails();
  }

  getColDefs() {
    this.columnDefs = [
      {
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 60,
        maxWidth: 60,
        pinned: 'left',
      },
      {
        headerName: 'SUPPLIER ID',
        field: 'suppliercode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'FIRST NAME',
        field: 'firstname',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'LAST NAME',
        field: 'lastname',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'CONTACT ID',
        field: 'suprole',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'ADDRESS',
        field: 'address',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 230,
      },
      {
        headerName: 'CITY',
        field: 'city',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'STATE',
        field: 'state',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'COUNTRY',
        field: 'country',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'PHONE',
        field: 'phone',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'TITLE',
        field: 'title',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'CONTACT DEFINITION',
        field: 'definitionId',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 230,
      },
      {
        headerName: 'EMAIL ID',
        field: 'email',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 230,
      },
    ];
  }

  fetchSupplierDetails() {
    this.ngxUiLoaderService.start(this.loaderVariable);
    this.massEmailService.massEmailDropDown()?.subscribe({
      next: (result) => {
        this.supplierCodeList = result;
        this.supplierCodeList.unshift({
          supplierCode: 'ALL',
          supplierName: 'ALL',
        });
        this.ngxUiLoaderService.stop(this.loaderVariable);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop(this.loaderVariable);
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  onSubmit() {
    if (this.myForm.valid || this.myForm.value.customSupplierCode) {
      this.selectedSupplierCode = this.myForm.value.supplierCode;
      this.selectedSupplierName = this.myForm.value.supplierName;
      this.showGrid = true;
      this.callingDetails();
    } else {
      this.myForm.markAllAsTouched();
    }
  }

  callingDetails() {
    this.ngxUiLoaderService.start(this.loaderVariable);
    const payload = {
      supplierId: this.myForm.value.supplierCode,
      selectedSupplierId: this.myForm.value.supplierCode,
      userId: this.user,
    };
    this.massEmailService.loadGridDetails(payload)?.subscribe({
      next: (result: SupplierContactData) => {
        this.displayRowData = [];
        this.displayRowData = result.supplierContactList.map(
          (val: SupplierContact) => {
            return {
              ...val,
              suppliercode:
                this.selectedRole.namc === '21TMC' &&
                this.myForm.value.supplierCode !== 'ALL'
                  ? this.selectedSupplierCode
                  : val.suppliercode,
            };
          }
        );
        this.ngxUiLoaderService.stop(this.loaderVariable);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop(this.loaderVariable);
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  supplierCodeChange() {
    const selectedSupplierName = this.supplierCodeList.find(
      (element) => element.supplierCode === this.myForm.value.supplierCode
    )?.supplierName;
    this.myForm.patchValue({
      supplierName: selectedSupplierName,
    });
  }

  supplierNameChange() {
    const selectedSupplierCode = this.supplierCodeList.find(
      (element) => element.supplierName === this.myForm.value.supplierName
    )?.supplierCode;
    this.myForm.patchValue({
      supplierCode: selectedSupplierCode,
    });
  }

  customSupplierCodeChange() {
    this.myForm.patchValue({
      supplierName: '',
      supplierCode: this.myForm.value.customSupplierCode,
    });
    this.supplierCodeChange();
    this.myForm.get('supplierCode')?.setErrors(null);
  }

  onRowSelection(event: SupplierContact[]) {
    this.multipleTo = [];
    this.emailsString = '';
    this.selectedRow = event;
    this.selectedRow.forEach((val: SupplierContact) => {
      this.multipleTo.push(val.email);
    });
    this.emailsString = this.multipleTo.join(', ');
  }

  openSendSuppNotification() {
    this.dialog
      .open(SendSupplierNotificationComponent, {
        width: '50%',
        height: '83%',
        data: {
          selectedRow: this.emailsString,
          selectedSuppId: this.myForm.value.supplierCode,
          selectedSuppName: this.myForm.value.supplierName,
          loggedInEmailId: this.loggedInEmailId,
        },
      })
      .afterClosed()
      ?.subscribe((result: string | undefined) => {
        if (result) {
          this.emailsString = '';
          this.callingDetails();
          this.successMessage = result;
          this.settingTimeOut();
        } else {
          return;
        }
      });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }

  exportSupplierList() {
    const headers = [
      'SUPPLIER ID',
      'FIRST NAME',
      'LAST NAME',
      'CONTACT ID',
      'ADDRESS',
      'CITY',
      'STATE',
      'COUNTRY',
      'PHONE',
      'TITLE',
      'CONTACT DETAILS',
      'EMAIL ID',
    ];
    const exportData: SupplierContact[] = [];
    this.displayRowData.forEach((e) => {
      const obj = {
        suppliercode: e.suppliercode,
        firstname: e.firstname,
        lastname: e.lastname,
        suprole: e.suprole,
        address: e.address,
        city: e.city,
        state: e.state,
        country: e.country,
        phone: e.phone,
        title: e.title,
        definitionId: e.definitionId,
        email: e.email,
      };
      exportData.push(obj);
    });
    this.excelService.exportAsExcelFile(
      exportData,
      'Supplier_Contacts_' + this.myForm.value.supplierCode,
      headers
    );
  }
}
