import { Component, Input, OnInit } from '@angular/core';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faComment, faDownload, faEye, faFilter, faShareFromSquare, faTruck } from '@fortawesome/free-solid-svg-icons';
import { AttributeDetails } from 'src/app/core/model/trial-order.model';

@Component({
  selector: 'app-supplier-trial-order-buttons',
  templateUrl: './supplier-trial-order-buttons.component.html',
  styleUrls: ['./supplier-trial-order-buttons.component.scss']
})
export class SupplierTrialOrderButtonsComponent implements OnInit {

  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faComment = faComment;
  faCheckSquareO = faCheckSquare;
  faEye = faEye;
  faWeibo = faWeibo;
  faFilter = faFilter;
  faCheck = faCheck;
  faDownload = faDownload;
  faTruck = faTruck;
  faShareFromSquare = faShareFromSquare;
  @Input() eventId = '';
  @Input() eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: ''
  };
  
  constructor() {
    //no work
   }

  ngOnInit(): void {
    //no work
  }

}
