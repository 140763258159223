<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="form-container">
  <form [formGroup]="form">
    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="title">TITLE</label>
      </div>
      <input
        id="title"
        formControlName="title"
        type="text"
        [ngClass]="{'is-invalid': form.get('title')?.touched && form.get('title')?.invalid && form.get('title')?.errors?.['required']}"
        disableSpecialCharacters
      />
      <div *ngIf="form.get('title')?.touched && form.get('title')?.invalid">
        <small
          *ngIf="form.get('title')?.errors?.['required']"
          class="errorValid"
          >Title is required.</small
        >
      </div>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="category">CATEGORY</label>
      </div>
      <p-dropdown
        id="category"
        formControlName="category"
        [options]="categoryList"
        optionLabel="categoryname"
        optionValue="categoryid"
        placeholder="Select Category"
        class="dropdownOptions"
        [ngClass]="{'is-invalid': form.get('category')?.touched && form.get('category')?.invalid && form.get('category')?.errors?.['required']}"
        [style]="{ fontSize: '13px', top: '6px' }"
      ></p-dropdown>

      <div
        *ngIf="form.get('category')?.touched && form.get('category')?.invalid" class="mt-2"
      >
        <small
          *ngIf="form.get('category')?.errors?.['required']"
          class="errorValid"
          >Category is required.</small
        >
      </div>
    </div>

    <div class="form-group">
      <div class="label-container">
        <label for="description">DESCRIPTION</label>
      </div>
      <textarea
        id="description"
        style="height: 66px"
        formControlName="description"
        rows="4"
        disableSpecialCharacters
      ></textarea>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="file">FILE</label>
      </div>
      <input id="file" type="file" (change)="onFileChange($event)" hidden />
      <button
        type="button"
        class="custom-file-upload"
        (click)="fileInput.click()"
      >
        Choose File
      </button>
      <input
        type="file"
        #fileInput
        (change)="onFileChange($event)"
        style="display: none"
        [attr.accept]="fileAcceptTypes"
      />
      <div *ngIf="uploadedFileName" class="file-info">
        <div class="file-detail file-name-align">
          <label for="name">FILENAME:</label>
          <a (click)="callRespectiveDownlaod()">{{ uploadedFileName }}</a>
        </div>

        <div class="file-detail">
          <label for="type">TYPE:</label>
          <span>{{ uploadedFileType }}</span>
        </div>

        <div class="file-detail">
          <label for="size">SIZE:</label>
          <span>{{ uploadedFileSize }}</span>
        </div>

        <div class="file-detail">
          <label for="uploadDate">UPLOAD DATE:</label>
          <span>{{ uploadedFileDate }}</span>
        </div>
      </div>
      <div *ngIf="form.get('file')?.touched && form.get('file')?.invalid">
        <small *ngIf="form.get('file')?.errors?.['required']" class="errorValid"
          >File is required.</small
        >
        <small
          *ngIf="form.get('file')?.errors?.['invalidFileType'] && category === 'S'"
          class="errorValid"
        >
          Please select a valid file type. The valid file types are .xlsx, .xls,
          .pdf, .dot, .doc, .docx, .pptx, .ppt, .m4v, .mp4, .pps.
        </small>
        <small
          *ngIf="form.get('file')?.errors?.['invalidFileType'] && category === 'N'"
          class="errorValid"
        >
          Please select a valid file type. The valid file types are .xlsx, .xls,
          .pdf, .dot, .doc, .docx, .pptx, .ppt, .pps.
        </small>
        <small
          *ngIf="form.get('file')?.errors?.['fileTooLarge'] && category === 'S'"
          class="errorValid"
        >
          The maximum file size can be 20 MB only. The MP4 file size should be
          less then 30 MB only.
        </small>
        <small
          *ngIf="form.get('file')?.errors?.['fileTooLarge'] && category === 'N'"
          class="errorValid"
        >
          The maximum file size can be 20 MB only.
        </small>
      </div>
    </div>
    <footer class="footer">
      <button class="cancel-button" (click)="cancel()">CANCEL</button>
      <div class="button-container">
        <button
          class="b2-button add"
          (click)="submit()"
          [disabled]="
            !this.form.controls['title'].value ||
            !this.form.controls['category'].value ||
            !uploadedFileName
          "
        >
          UPLOAD & SAVE
        </button>
      </div>
    </footer>
  </form>
</div>
