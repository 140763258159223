import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-edit-configuration',
  template: `<button [disabled]="params.data?.isEditable === 'N' || params.data?.isEditable === 'n'" class="edit"
  (click)="modifyConfiguration()"
  (keydown)="modifyConfiguration()">
    <fa-icon
      [icon]="faEdit"
      title="Modify Configuration"
    ></fa-icon>
  </button>`,
  styles: [
    `
      .edit {
        width: 26px;
        height: 26px;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #d1d1d1;
         border-radius: 4px;
        opacity: 1;
        box-shadow: 0.2s;
      }
      .edit:active{
          transform: scale(0.95) !important;
          box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
      }
      button:disabled {
       cursor: not-allowed;
      }
    `,
  ],
})
export class EditConfigurationComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  faEdit = faPencilAlt;
  constructor(private readonly router: Router) {}

  refresh(_params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  modifyConfiguration() {
    const params = {
      configId: this.params.data?.configId,
      configKeyName: this.params.data?.configKeyName,
      configKeyValue: this.params.data?.configKeyValue,
      commentsText: this.params.data?.commentsText,
    };
    sessionStorage.setItem('modifyConfigurationParams', JSON.stringify(params));
    this.router.navigate(
      ['/tmna-namc/manage-configurations/modify-configuration']
    );
  }
}
