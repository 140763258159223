import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ManageAttributesComponent } from 'src/app/pages/tmna-namc/manage-attributes/manage-attributes.component';
import { AttributeList } from '../../model/manage-attributes.model';

@Component({
  selector: 'app-edit-attribute',
  template: `<button [disabled]="params.data?.isEditable === 'N' || params.data?.isEditable === 'n'" class=" edit me-3"
        (click)="modifyAttribute()"
        (keydown)="modifyAttribute()">
      <fa-icon
        [icon]="faEdit"
        title="Modify Attribute"
      ></fa-icon>
    </button>
    <button [disabled]="params.data?.isEditable === 'N' || params.data?.isEditable === 'n'" class="edit"
     (click)="deleteAttribute()"
    >
      <fa-icon
        [icon]="faDelete"
        title="Delete Attribute"
      ></fa-icon>
    </button>`,
    styles: [
      `
        .edit {
          width: 26px;
          height: 26px;
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #d1d1d1;
           border-radius: 4px;
          opacity: 1;
          box-shadow: 0.2s;
        }
        .edit:active{
          transform: scale(0.95) !important;
          box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
        }
          button:disabled {
       cursor: not-allowed;
      }
      `,
    ],
})
export class ManageAttributeAction implements ICellRendererAngularComp {
  params!: ICellRendererParams<
    AttributeList,
    undefined,
    { componentParent: ManageAttributesComponent }
  >;
  faEdit = faPencilAlt;
  faDelete = faTrashAlt;
  constructor(private readonly router: Router) {}

  refresh(
    _params: ICellRendererParams<
      AttributeList,
      undefined,
      { componentParent: ManageAttributesComponent }
    >
  ): boolean {
    return false;
  }

  agInit(
    params: ICellRendererParams<
      AttributeList,
      undefined,
      { componentParent: ManageAttributesComponent }
    >
  ): void {
    this.params = params;
  }

  modifyAttribute() {
    const params = {
      attributeID: this.params.data?.attributeID,
      attributeType: this.params.data?.attributeType,
      attributeValue: this.params.data?.attributeValue,
      attributeDescription: this.params.data?.attributeDescription,
    };
    sessionStorage.setItem('modifyAttributeParams', JSON.stringify(params));
    this.router.navigate(['/tmna-namc/manage-attributes/modify-attribute']);
  }
  deleteAttribute() {
    if (this.params.data?.attributeID) {
      this.params?.context.componentParent.deleteAttribute(
        this.params.data.attributeID,
        this.params.data.attributeValue
      );
    }
  }
}
