<div class="grid-agg px-0">
  <ag-grid-angular
    class="ag-theme-balham grid-items"
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs"
    [rowData]="displayRowData"
    [defaultColDef]="defaultColDef"
    [icons]="icons"
    [suppressCellFocus]="true"
    [suppressCellSelection]="true"
    [headerHeight]="40"
    [context]="context"
    [pagination]="true"
    [gridOptions]="gridOptions"
    [rowClassRules]="rowClassRules"
    (paginationChanged)="onPaginationChanged()"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [suppressPaginationPanel]="true"
    (modelUpdated)="onModelUpdated($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [rowSelection]="rowSelection"
    (window:resize)="sizeToFit()"
  ></ag-grid-angular>
  <div
    class="d-flex pagination px-2 align-items-center justify-content-between"
  >
    <div>
      <div>
        <label for="Records/Page" class="page-drpdwn-label me-2"
          >Records/Page:
        </label>
        <select (change)="onPageSizeChanged($event)" class="paginate_drpdwn">
          <option value="15" selected>15</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="{{ displayRowData.length }}">ALL</option>
        </select>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3">
      <div>Viewing Records</div>

      <fa-icon
        [icon]="faFastBackward"
        width="10"
        (click)="goToFirstPage()"
        (keydown)="goToFirstPage()"
        alt="First Page"
        class="text-primary firstPage"
        [ngClass]="{
          disable_icon: getCurrentPage() == true,
          enable_icon: getCurrentPage() != true
        }"
      ></fa-icon>

      <fa-icon
        [icon]="faStepBackward"
        (click)="goToPreviousPage()"
        (keydown)="goToPreviousPage()"
        alt="Previous Page"
        class="text-primary previousPage"
        [ngClass]="{
          disable_icon: getCurrentPage() == true,
          enable_icon: getCurrentPage() != true
        }"
      >
      </fa-icon>

      <div>
        (
        <span class="value">{{ lbCurrentPage }}</span> -
        <span class="value">{{ lbTotalPages }}</span
        >)&nbsp; OF &nbsp;
        <span>{{ displayRowData.length }}</span>
      </div>

      <fa-icon
        [icon]="faStepForward"
        (click)="goToNextpage()"
        (keydown)="goToNextpage()"
        alt="Next Page"
        class="text-primary nextPage"
        [ngClass]="{
          disable_icon: isLastPage() == true || displayRowData.length == 0,
          enable_icon: isLastPage() != true || displayRowData.length > 0
        }"
      >
      </fa-icon>
      <fa-icon
        [icon]="faFastForward"
        width="10"
        (click)="goToLastPage()"
        (keydown)="goToLastPage()"
        alt="Last Page"
        class="text-primary lastPage"
        [ngClass]="{
          disable_icon: isLastPage() == true || displayRowData.length == 0,
          enable_icon: isLastPage() != true || displayRowData.length > 0
        }"
      >
      </fa-icon>
    </div>
  </div>
</div>
