
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="upperText">
  Please Select A Supplier Code From The Dropdown Or Enter A Custom Supplier
  Code To Search
</div>
<div class="container">
  <div class="tile">
    <form [formGroup]="myForm">
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierCode">SUPPLIER CODE</label>
        </div>
        <p-dropdown
          formControlName="supplierCode"
          [options]="supplierCodeList"
          optionLabel="supplierCode"
          optionValue="supplierCode"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          [virtualScrollItemSize]="10"
          (onChange)="supplierCodeChange()"
        >
        </p-dropdown>
        <div
          *ngIf="
            myForm.get('supplierCode')?.touched &&
            myForm.get('supplierCode')?.invalid && !myForm.value.customSupplierCode
          "
        >
          <small
            *ngIf="myForm.get('supplierCode')?.errors?.['required']"
            class="errorValid"
            >Supplier Code is required.</small
          >
        </div>
      </div>
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierName">SUPPLIER NAME</label>
        </div>
        <p-dropdown
          formControlName="supplierName"
          [options]="supplierCodeList"
          [virtualScrollItemSize]="10"
          optionLabel="supplierName"
          optionValue="supplierName"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          (onChange)="supplierNameChange()"
        >
        </p-dropdown>
        <div
          *ngIf="
            myForm.get('supplierName')?.touched &&
            myForm.get('supplierName')?.invalid && !myForm.value.customSupplierCode
          "
        >
          <small
            *ngIf="myForm.get('supplierName')?.errors?.['required']"
            class="errorValid"
            >Supplier Name is required.</small
          >
        </div>
      </div>
      <div class="input-container">
        <label for="customSupplierCode">CUSTOM SUPPLIER CODE</label>
        <input formControlName="customSupplierCode" type="text" id="customSupplierCode" name="customSupplierCode" (change)="customSupplierCodeChange()"/>
        <button class="b2 add" (click)="onSubmit()">SUBMIT</button>
      </div>
    </form>
  </div>
</div>
<!-- table.component.html -->
<div *ngFor="let supplierCode of contactList; let j = index">
  <div class="tile1" *ngIf="hideDetails">
    <div class="caption">{{supplierName}} ( {{ supplierCode }} ) CONTACT INFORMATION</div>
    <div>
      <div
        *ngFor="let contact of contactDetails[j]; let i = index"
        [ngClass]="{ 'even-tile': i % 2 === 0, 'odd-tile': i % 2 !== 0 }"
      >
        <div class="tile1-content">
          <div class="row">
            <div class="col">
              <span class="label">FIRST NAME:</span>
              <span class="detail">{{ contact.firstname }}</span>
            </div>
            <div class="col">
              <span class="label">CONTACT ID:</span>
              <span class="detail">{{ contact.suprole }}</span>
            </div>
            <div class="col">
              <span class="label">ADDRESS:</span>
              <span class="detail">{{ contact.address }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">LAST NAME:</span>
              <span class="detail">{{ contact.lastname }}</span>
            </div>
            <div class="col">
              <span class="label">PHONE:</span>
              <span class="detail">{{ contact.phone }}</span>
            </div>
            <div class="col">
              <span class="label">CITY:</span>
              <span class="detail">{{ contact.city }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">TITLE:</span>
              <span class="detail">{{ contact.title }}</span>
            </div>
            <div class="col">
              <span class="label">EMAIL:</span>
              <span class="detailEmail">{{ contact.email }}</span>
            </div>
            <div class="col">
              <span class="label">STATE - COUNTRY:</span>
              <span class="detail">{{ contact.country }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">CONTACT DEF:</span>
              <span class="detail">{{ contact.definitionId }}</span>
            </div>
            <div class="col">
              <span class="label"></span>
              <span class="detail"></span>
            </div>
            <div class="col">
              <span class="label">ZIP:</span>
              <span class="detail"></span>
            </div>
          </div>
        </div>
        <hr class="styled-hr" />
      </div>
    </div>
  </div>
</div>
