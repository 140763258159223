<div
  class="d-flex justify-content-between flex-wrap footer align-items-center me-2 ms-2"
>
  <div class="d-flex justify-content-start">
    <button class="mt-3 me-2">
      <fa-icon
        [icon]="faFilter"
        title="Filter Grid"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
    <button class="mt-3 me-2">
      <fa-icon
        [icon]="faColumns"
        title="Save Columns"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
  </div>

  <div class="d-flex justify-content-end">
    <button class="mt-3 me-2">
      <fa-icon
        [icon]="faEye"
        title="View Single Order"
        class="d-flex justify-content-center"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faPlus" title="Create New Order"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faPlus" title="Create New Order"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faList" title="Append Multiple Orders"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faList" title="Append Multiple Orders"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faTrash" title="Cancel Order(s)"></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faTrash" title="Cancel Order(s)"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon
        [icon]="faCheckSquareO"
        title="Review Supplier Ship Date"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon
        [icon]="faCheckSquareO"
        title="Review Supplier Ship Date"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faThumbsUp" title="Accept Supplier Ship Date"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faThumbsUp" title="Accept Supplier Ship Date"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon
        [icon]="faThumbsDown"
        title="Reject Supplier Ship Date"
      ></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'true' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon
        [icon]="faThumbsDown"
        title="Reject Supplier Ship Date"
      ></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faComment" title="NAMC Comments"></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faComment" title="NAMC Comments"></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      title="Send to SAP"
      style="width: 72px"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faEnvelope" class="me-1"></fa-icon>SAP
    </button>
    <button
      class="mt-3 me-2"
      style="width: 72px"
      title="Enable for SAP"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faThumbsUp" class="me-1"></fa-icon>SAP
    </button>
    <button
      class="mt-3 me-2"
      title="Disable for SAP"
      style="width: 72px"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        eventDetailsFlags.showEdit === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faThumbsDown" class="me-1"></fa-icon>SAP
    </button>
    <button class="mt-3 me-2">
      <fa-icon [icon]="faShareFromSquare" title="Export Orders"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin'
      "
    >
      <fa-icon [icon]="faEnvelope" title="Send Supplier Notification"></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        this.currentRole === 'IS Admin' ||
        this.currentRole === 'QE User' ||
        this.currentRole === 'PE User' ||
        this.currentRole === 'Shop User' ||
        this.currentRole === 'PC User'
      "
      disabled
    >
      <fa-icon [icon]="faEnvelope" title="Send Supplier Notification"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false' &&
        (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
      "
    >
      <fa-icon [icon]="faCalendar" title="Supplier Ship Date"></fa-icon>
    </button>

    <button
      class="mt-3 me-2"
      *ngIf="
        (eventDetailsFlags.isAllEvents === 'false' ||
          eventDetailsFlags.isEventDeleted === 'false') &&
        (this.currentRole === 'IS Admin' ||
          this.currentRole === 'QE User' ||
          this.currentRole === 'PE User' ||
          this.currentRole === 'Shop User' ||
          this.currentRole === 'PC User')
      "
      disabled
    >
      <fa-icon [icon]="faCalendar" title="Supplier Ship Date"></fa-icon>
    </button>
    <button
      class="mt-3 me-2"
      *ngIf="
        eventDetailsFlags.isAllEvents === 'false' &&
        eventDetailsFlags.isEventDeleted === 'false'
      "
    >
      <fa-icon
        [icon]="faWeibo"
        title="Supplier Ship Date Change Request"
      ></fa-icon>
    </button>
  </div>
</div>
