
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  class="gridCss"
></app-grid>
