import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { FullUserData, UserRoleMapping } from 'src/app/core/model/manage-user';
import { NAMC, NamcRole } from 'src/app/core/model/namc.model';
import { User } from 'src/app/core/model/user.model';
import { AddEditUserService } from 'src/app/services/tmna-namc/add-edit-user.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit {
  selectedRole: number[] = [];
  isInputDisable = true;
  isSearchBtnDisable = true;
  isSearchBtnClicked = false;
  isStatusChanged = false;
  isAddBtnDisable = false;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  firstName = '';
  lastName = '';
  contactNumber = '';
  userActiveFlag = '';
  title = '';
  email = '';
  userId = '';
  guid = '';
  namcValue = '';
  spclCodeValue: number[] = [];
  changedSpclCode: string[] = [];
  copyNamcCode: string[] = [];
  roleAtLocation: { [namcCode: string]: number } = {};
  spclCodeAtLocation: { [namcCode: string]: number } = {};
  mappingList: FullUserData | null = null;
  user: User | null = null;
  roles: any[] = [];
  namcRoleList: NamcRole = {
    namcList: [],
    roleList: [],
  };
  options = [
    { value: 'Y', label: 'Active' },
    { value: 'N', label: 'Inactive' },
  ];
  requiredDisplayCheck = true;
  indices: number[] = [];
  editRoleName = '';
  currentRoleName = '';
  operation = '';

  constructor(
    private readonly router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly addEditUserService: AddEditUserService,
    private readonly userService: UserService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.editRoleName = history.state?.roleData;
    this.currentRoleName = String(sessionStorage.getItem('roleName'));
    this.operation = String(sessionStorage.getItem('operation'));
    this.userService.userDetails.subscribe((value) => {
      if (value !== null) {
        this.user = value;
      }
    });
    this.getNamcValues(this.operation);
  }

  onInputChange(index: string) {
    this.changedSpclCode.push(index);
    this.indices = this.changedSpclCode.map((code) =>
      this.namcRoleList.namcList.findIndex((item) => item.code === code)
    );
  }

  getNamcValues(operation: string) {
    this.ngxUiLoaderService.start('namcLoad');
    this.addEditUserService.getNamcList(operation)?.subscribe({
      next: (result: NamcRole) => {
        this.ngxUiLoaderService.stop('namcLoad');
        this.namcRoleList = result;
        this.namcRoleList.roleList = this.namcRoleList.roleList.filter(
          (value) => value.roleDesc !== 'Supplier'
        );
        this.namcRoleList.roleList.push({ roleDesc: '(Select)', roleId: 0 });
        this.namcRoleList.roleList.sort(function (a, b) {
          return a.roleId - b.roleId;
        });
        if (
          this.currentRoleName === 'NAMC Admin' &&
          (this.editRoleName === 'QE User' ||
            this.editRoleName === 'PC User' ||
            this.editRoleName === 'PE User' ||
            this.editRoleName === 'Shop User')
        ) {
          const indexToRemove = this.namcRoleList.roleList.findIndex(
            (obj) => obj.roleId === 3
          );
          if (indexToRemove !== -1) {
            this.namcRoleList.roleList.splice(indexToRemove, 1);
          }
        }
        this.namcRoleList.namcList.forEach((element) => {
          this.copyNamcCode.push(element.code);
        });
        if (this.router.url === '/tmna-namc/manage-users/add') {
          this.isInputDisable = false;
          this.isSearchBtnDisable = false;
        } else {
          this.userId = sessionStorage.getItem('retrieveUserId') ?? '';
          this.search('edit');
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('namcLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  search(screen: string) {
    this.isSearchBtnClicked = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    if (this.userId === '') {
      this.isSearchBtnClicked = true;
      this.isAddBtnDisable = false;
      return;
    }
    this.ngxUiLoaderService.start('userSearch');
    this.addEditUserService
      .searchUserDetails(this.userId, this.operation)
      .subscribe({
        next: (result: FullUserData) => {
          this.ngxUiLoaderService.stop('userSearch');
          this.setFormField(screen, result);
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('userSearch');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error.error;
          setTimeout(() => {
            this.errorMessage = undefined;
            this.successMessage = undefined;
          }, 5000);
        },
      });
  }

  setFormField(screen: string, result: FullUserData) {
    if (screen === 'add') {
      this.firstName = '';
      this.lastName = '';
      this.contactNumber = '';
      this.email = '';
      this.title = '';
      this.guid = '';
      this.userActiveFlag = '';
      if (result.status === 206) {
        this.isAddBtnDisable = true;
        this.firstName = result.data.firstName;
        this.lastName = result.data.lastName;
        this.contactNumber =
          result.data.contactNumber === 'null' ? '' : result.data.contactNumber;
        this.email = result.data.emailId;
        this.title = result.data.title;
        this.guid = result.data.guid;
        this.setMappingDetails(result);
      } else if (result.status === 200) {
        this.isAddBtnDisable = false;
        this.errorMessage = undefined;
        this.errorMessage = 'UserId already exist in system';
      } else if (result.status === 205) {
        this.isAddBtnDisable = false;
        this.errorMessage = undefined;
        this.errorMessage = 'Supplier User already exist in system.';
      } else {
        this.isAddBtnDisable = false;
        this.errorMessage = undefined;
        this.errorMessage = 'User does not exist in database';
      }
    } else if (screen === 'edit') {
      this.userId = result.data.userId;
      this.firstName = result.data.firstName;
      this.lastName = result.data.lastName;
      this.contactNumber =
        result.data.contactNumber === 'null' ? '' : result.data.contactNumber;
      this.email = result.data.emailId;
      this.title = result.data.title;
      this.userActiveFlag = result.data.userActiveFlag;
      this.guid = result.data.guid;
      this.setMappingDetails(result);
    }
  }

  setMappingDetails(result: FullUserData) {
    this.namcRoleList.namcList = this.namcRoleList.namcList.map(
      (item: NAMC) => {
        return {
          ...item,
          roleId:
            result.data.mappingDetails?.find(
              (role: UserRoleMapping) => role.namcCode === item.code
            )?.roleId ?? null,
          userSpecialCode:
            result.data.mappingDetails?.find(
              (role: UserRoleMapping) => role.namcCode === item.code
            )?.userSpecialCode ?? null,
        };
      }
    );
    this.namcRoleList.namcList.forEach((element: any) => {
      this.selectedRole.push(element?.roleId);
      this.spclCodeValue.push(element?.userSpecialCode ?? null);
    });
  }

  onSubmit() {
    let j = 0;
    this.isStatusChanged = false;
    this.isSearchBtnClicked = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    if (this.userId === '') {
      this.isSearchBtnClicked = true;
      return;
    }
    if (this.userActiveFlag === '') {
      this.isStatusChanged = true;
      return;
    }
    const containsNonNullNonZeroValue = this.selectedRole.some(
      (value) => value !== null && value !== 0
    );
    if (!containsNonNullNonZeroValue) {
      this.errorMessage = 'Please select atleast 1 NAMC.';
      this.settingTimeOut(2000);
      return;
    }
    for (const i of this.indices) {
      if (this.selectedRole[i] === null && this.spclCodeValue[i] !== null) {
        this.errorMessage = 'Please Select at least 1 NAMC';
        this.settingTimeOut(2000);
        break;
      }
    }
    if (this.errorMessage) {
      return;
    }
    this.spclCodeValue.forEach((i: number) => {
      if (this.changedSpclCode.includes(this.copyNamcCode[j])) {
        this.spclCodeAtLocation[this.copyNamcCode[j]] = Number(i);
      }
      j++;
    });
    const payload = {
      operation: this.operation,
      userId: this.userId,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      userStatus: this.userActiveFlag,
      contactNumber: null,
      email: this.email,
      guid: this.guid,
      spclCodeAtLocation: this.spclCodeAtLocation,
      roleAtLocation: this.roleAtLocation,
      loggedInUserId: this.user?.userId,
    };
    this.changedSpclCode = [];
    this.spclCodeAtLocation = {};
    this.roleAtLocation = {};
    this.ngxUiLoaderService.start('submitUserDetails');
    this.addEditUserService.submitUserDetails(payload)?.subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('submitUserDetails');
        this.addEditUserService.setMessage(
          this.isSearchBtnDisable
            ? `User ${this.userId} Details Updated Successfully`
            : `New User ${this.userId} Added Successfully`
        );
        this.router.navigateByUrl('/tmna-namc/manage-users');
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('submitUserDetails');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        this.settingTimeOut(5000);
      },
    });
  }

  roleChange(event: any, code: string) {
    this.roleAtLocation[code] = event.value;
  }

  settingTimeOut(time: number) {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, time);
  }

  cancel() {
    if (this.isSearchBtnDisable) {
      const dialogRef = this.dialog.open(WarningPopupComponent, {
        disableClose: true,
        width: '570px',
        height: '219px',
      });

      dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
        if (result === undefined) {
          return;
        }
        if (result === 'Success') {
          this.router.navigate(['/tmna-namc/manage-users']);
        }
      });
    } else {
      window.history.back();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const allowedCharacters = /^\d*$/;
    const char = event.key;
    const allowedSpecialKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
      'Escape',
    ];
    if (!allowedCharacters.test(char) && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
    if (inputValue.length >= 2 && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
  }
}
