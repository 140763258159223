import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-edituserrolerenderer',
  template: `
    <button
      [disabled]="!showEditIcon"
      class="d-flex justify-content-center edit me-3"
      (click)="routeToEditUser($event)"
      (keydown)="routeToEditUser($event)"
    >
      <fa-icon
        [icon]="faEdit"
        title="Edit User"
      ></fa-icon>
    </button>
  `,
  styles: [
    `
      .edit {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid #d1d1d1;
        border-radius: 4px;
        background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat;
        cursor: pointer;
        box-sizing: border-box;
        padding: 5px;
        transition: background-color 0.3s ease;
        box-shadow: 0.2s;
      }
      .edit:active{
        transform: scale(0.95) !important;
        box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
      }
      button:disabled {
       cursor: not-allowed;
      }
    `
    ,
  ],
})
export class EditUserRoleRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  faEdit = faPencilAlt;
  showEditIcon = true;

  constructor(public router: Router) {}

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams) {
    this.params = params;
    if (this.params.data.roles.length === 1) {
      if (this.params.data.roles[0].roleDesc === 'Supplier') {
        this.showEditIcon = false;
      }
    }
  }

  routeToEditUser(event: any) {
    if (!this.showEditIcon) {
      event.preventDefault();
      return;
    }
    sessionStorage.setItem('retrieveUserId', this.params.data.userId);
    sessionStorage.setItem('operation','edit')
    this.router?.navigate(['tmna-namc/manage-users/edit/'], {
      state: { roleData: this.params.data.roles[0].roleDesc },
    });
  }
}
