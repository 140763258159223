import { Component, Input, OnInit } from '@angular/core';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faCalendar, faCheckSquare, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faColumns, faComment, faEnvelope, faEye, faFilter, faList, faPlus, faShareFromSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AttributeDetails } from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';

@Component({
  selector: 'app-namc-trial-order-buttons',
  templateUrl: './namc-trial-order-buttons.component.html',
  styleUrls: ['./namc-trial-order-buttons.component.scss']
})
export class NamcTrialOrderButtonsComponent implements OnInit {

  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faComment = faComment;
  faCheckSquareO = faCheckSquare;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faEye = faEye;
  faPlus = faPlus;
  faList = faList;
  faTrash = faTrash;
  faEnvelope = faEnvelope;
  faShareFromSquare = faShareFromSquare;
  faCalendar = faCalendar;
  faWeibo = faWeibo;
  faFilter = faFilter;
  faColumns = faColumns;
  user: User | null = null;
  @Input() currentRole = '';
  @Input() eventId = '';
  @Input() eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: ''
  };

  constructor() {
    //no work
  }

  ngOnInit(): void {
    //no work
  }

}
