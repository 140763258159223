
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
></app-notification-message>
<div class="d-flex mt-4 col-4">
  <div class="d-flex mx-4" style="flex-direction: column">
    <span class="required-color bold-label header-display px-2 mb-2">CONFIGURATION ID</span>
    <input
      type="text"
      [disabled]="true"
      class="required-color"
      for="configurationId"
      size="100"
      [(ngModel)]="configId"
    />
  </div>
</div>
<div class="d-flex mt-4 col-4">
  <div class="d-flex mx-4" style="flex-direction: column">
    <span class="required-color bold-label header-display px-2 mb-2">CONFIGURATION KEY</span>
    <input
      type="text"
      [disabled]="true"
      for="configurationKey"
      size="100"
      [(ngModel)]="configKey"
    />
  </div>
</div>
<form [formGroup]="modifyConfigurationForm">
  <div class="d-flex mt-4 col-4">
    <div class="d-flex mx-4" style="flex-direction: column">
      <span class="bold-label required-color header-display px-2 mb-2"
        >CONFIGURATION VALUE</span
      >
      <input
        type="text"
        for="configurationValue"
        size="100"
        formControlName="configValue"
        maxlength="100"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div
    class="d-flex mx-4"
    *ngIf="
      this.modifyConfigurationForm.controls['configValue'].invalid &&
      (this.modifyConfigurationForm.controls['configValue'].dirty ||
        this.modifyConfigurationForm.controls['configValue'].touched)
    "
  >
    <small
      class="error col-4"
      *ngIf="
        this.modifyConfigurationForm.controls['configValue'].hasError(
          'required'
        )
      "
    >
      Please provide Configuration Value
    </small>
  </div>
  <div class="d-flex mt-4 col-4">
    <div class="d-flex mx-4" style="flex-direction: column">
      <span class="required-color bold-label header-display px-2 mb-2"
        >CONFIGURATION DESCRIPTION</span
      >
      <input
        type="text"
        for="configurationDescription"
        size="100"
        formControlName="configDescription"
        maxlength="70"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div
    class="d-flex mx-4"
    *ngIf="
      this.modifyConfigurationForm.controls['configDescription'].invalid &&
      (this.modifyConfigurationForm.controls['configDescription'].dirty ||
        this.modifyConfigurationForm.controls['configDescription'].touched)
    "
  >
    <small
      class="error col-4"
      *ngIf="
        this.modifyConfigurationForm.controls['configDescription'].hasError(
          'required'
        )
      "
    >
      Please provide Configuration Description
    </small>
  </div>
</form>
<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button
      class="b2-button add"
      (click)="updateConfiguration()"
    [disabled]="
      !this.modifyConfigurationForm.controls['configValue'].value ||
      !this.modifyConfigurationForm.controls['configDescription'].value
    "
    >
      UPDATE CONFIGURATION
    </button>
  </div>
</footer>
