<header>
  <label class="close_button" (click)="close()" (keydown)="close()">x</label>
  <div class="header d-block roboto-medium">
    <label class="ms-3 mt-4 mb-3">Supplier Notification</label>
  </div>
</header>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>

<form [formGroup]="form" enctype="multipart/form-data" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="form-group col">
      <label for="from" class="form-label">From</label><br />
      <input
        type="text"
        class="form-control"
        id="from"
        formControlName="from"
        [disabled]="!form.get('addFrom')?.value"
      />
    </div>
    <div class="form-group col">
      <label for="to" class="form-label">To</label><br />
      <input
        type="text"
        class="form-control"
        id="to"
        formControlName="to"
        [disabled]="true"
      />
    </div>
  </div>

  <div class="form-row d-flex">
    <div class="form-group col">
      <label
        for="subject"
        class="form-label required-color px-2"
        style="margin-left: 1.2rem"
        >Subject: TPROMS -</label
      >
      <input
        type="text"
        class="form-control"
        id="subject"
        formControlName="subject"
        [ngClass]="{
          'invalid-input':
            !!form.get('subject')?.invalid &&
            (!!form.get('subject')?.touched || !!form.get('subject')?.dirty)
        }"
      />
    </div>
    <div class="form-group col">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="addCC"
          formControlName="addCC"
        />
        <label class="form-check-label" for="addCC">Add CC</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="checkbox"
          id="addFrom"
          formControlName="addFrom"
        />
        <label class="form-check-label" for="addFrom">Add FROM</label>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label for="cc" class="form-label">CC</label>
      <input
        type="text"
        class="form-control"
        id="cc"
        formControlName="cc"
        [disabled]="!form.get('addCC')?.value"
      />
    </div>
    <div class="form-group col d-flex">
      <div>
        <input
          type="file"
          style="display: none"
          multiple="true"
          (change)="onFileSelected($event)"
          #fileUpload
        />
      </div>
      <button
        type="button"
        class="addfile-btn me-3 roboto-bold"
        (click)="fileUpload.click()"
      >
        CLICK TO ADD FILES
      </button>
    </div>
  </div>

  <div>
    <table
      *ngIf="uploadFileName.length !== 0"
      style="border: 1px solid #bcd1e0; margin-left: 20px"
      aria-label="filesAttatched"
    >
      <thead>
        <tr class="headerinfo">
          <th colspan="2">FILES ATTATCHED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fileName of uploadFileName">
          <td>{{ fileName }}</td>
          <td>
            <fa-icon
              [icon]="faDelete"
              style="color: red"
              (click)="removeFile(fileName)"
              (keydown)="removeFile(fileName)"
            ></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />

  <div class="form-group">
    <label
      for="message"
      class="form-label required-color px-2"
      style="margin-left: 1.2rem"
      >Message</label
    >
    <textarea
      class="form-control"
      id="message"
      rows="5"
      formControlName="message"
      [ngClass]="{
        'invalid-input':
          !!form.get('message')?.invalid &&
          (!!form.get('message')?.touched || !!form.get('message')?.dirty)
      }"
    ></textarea>
  </div>
</form>

<div
  class="footer d-flex justify-content-center align-items-center fixed-bottom pt-2 pb-2"
>
  <button class="submit black-button me-3 roboto-bold" (click)="onSubmit()">
    Send
  </button>
  <button class="popup-cancel-btn me-3 roboto-bold" (click)="close()">
    CANCEL
  </button>
</div>
