<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">
    <fa-icon class="me-1" [icon]="faWarning"></fa-icon>
    Warning
  </h2>
  <hr id="shadow" />
  <br />
  <div>
    <p class="roboto-bold paragraph">Are you sure you want to exit without saving?</p>
    <p class="roboto-bold paragraph">Click on "Cancel" to return and save your changes.</p>
  </div>
  <div class="right-align mt-2">
    <button
      (click)="continueWithoutSaving()"
      class="b2-button add roboto-medium"
    >
      CONTINUE WITHOUT SAVING
    </button>
    <button (click)="closeDialog()" class="b2 me-3 ms-3 roboto-bold">
      CANCEL
    </button>
  </div>
</div>
